import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { motion } from 'framer-motion';

const Ringgroup = () => {

 // const [postsPerPage] = useState(5);
 const [offset, setOffset] = useState(1);
 const [posts, setAllPosts] = useState([]);
 const [pageCount, setPageCount] = useState(0)

 const getAllPosts = async () => {
    try{
     const res = await axios.get(process.env.REACT_APP_IP+`/User/RestAllRinggroup?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
     const data = res.data;
     const postData = getPostData(data)
     // Using Hooks to set value
     setAllPosts(postData)
     // setPageCount(Math.ceil(data.length / postsPerPage))
     setPageCount(Math.ceil(data.totalPages))
    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }
 }
 
 useEffect(() => {
    getAllPosts()
}, [offset])


// deleteCampaign
const DeleteRinggroup = async (e) => {
try{
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteRinggroup", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
   const json = await response.json()
   console.log(json.msg)
   
   if (json.type === "OK") {
    // props.showAlert(json.msg, "success");
    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Success',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 2500
    })
} else {
    //props.showAlert(json.msg, "danger");
    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 4500
    })
}
    
    getAllPosts()
    
} catch (error) {
    console.error('Error In getAllPosts ', error);
  }



}

const onToggle = async (e) => {
    try{
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/ActiveGroup", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
           // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }
    getAllPosts()
   // setIsToggled(!isToggled);
} catch (error) {
    console.error('Error In getAllPosts ', error);
  }

}
const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
};

const getPostData = (data) => {
    return (
        <>
          

                                        {data.content.map((page,index) => {
                                            return (
                                                <tr key={page.id}>
                                                      <td>{(offset - 1)*5+index+1}</td> 
                                                    <td>{page.userid}</td>
                                                    <td>{page.name}</td>
                                                    <td>{page.namedesc}</td>
                                                    <td>{page.uploaddate}</td>

                                                    <td>

                                                        <label className="toggle-switch">
                                                            <input type="checkbox" checked={page.active} 
                                                            onChange={() => onToggle(page.id)}
                                                             />
                                                            <span className="switch" />
                                                        </label>

                                                    </td>
                                                    <td>{page.strategy}</td>
                                                    <td>{page.ringback}</td>
                                                    <td>{page.timeout}</td>
                                                    
                                                    <td>
                                                        <button className=" mx-2 btn btn-danger" 
                                                        onClick={() => DeleteRinggroup(page.id)}
                                                        >
                                                            <i className="fa fa-trash" ></i>
                                                        </button>

                                                         {/* <i className="fa fa-trash mx-2" onClick={() => deleteCampaign(page.id)}></i> */}
                                                         
                                                         
                                                         </td>




                                                </tr>
                                            );
                                        })}
                                    
        </>

    )

}


 const onInputChange = (event) => {

     const offset = Math.ceil(event.target.value);
     setOffset(offset);

 }
   
    
    const [ringbackaudio, setRingbackaudio] = useState("");
    const [timeoutaudio, setTimeoutaudio] = useState("");
    const[active,setActive] =  useState("true");
    const[groupname,setGroupname] =  useState("");
    const[groupdes,setGroupdes] =  useState("");
    const[strategy,setStrategy] =  useState("");
    const [uploaduserid, setUploaduserid] = useState("NA");
    


     const Ringgroupsubmitfun = async(event) =>{
        try{
        event.preventDefault();
        // alert(
        //     ">>>>>>ringbackaudio>>>"+ringbackaudio+
        //     ">>>>>>timeoutaudio>>>"+timeoutaudio+
        //     ">>>>>>active>>>"+active+
        //     ">>>>>>groupname>>>"+groupname+
        //     ">>>>>>groupdes>>>"+groupdes+
        //     ">>>>>>strategy>>>"+strategy+
        //     ">>>>>>uploaduserid>>>"+uploaduserid
        // )

        var formdata = new FormData()
        formdata.append('userid', uploaduserid)
        formdata.append('name', groupname)
        formdata.append('namedesc', groupdes)
        formdata.append('strategy', strategy)
        formdata.append('ringbackid', ringbackaudio)
        formdata.append('timeoutid', timeoutaudio)
        formdata.append('active', active)
        
       
        

    const response = await fetch(process.env.REACT_APP_IP+"/User/AddRinggroup", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body:formdata
        // body: JSON.stringify({
           
        // })

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }

    getAllPosts()

} catch (error) {
    console.error('Error In getAllPosts ', error);
  }

     }

    const optionaudio = async (e) => {
        try{
        var formdata = new FormData()
        formdata.append('username', e)
        //alert(">>>>"+e)
        const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllAudioSelect", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const jsona = await resppe.json()
        //console.log(jsona.length)

        var ele = document.getElementById('ringbackaudioid');
        ele.innerHTML = ""+('<option value="NA">NA</option>');
        
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            ele.innerHTML = ele.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }


        var eleedit = document.getElementById('timeoutaudioid');
        eleedit.innerHTML = ""+('<option value="NA">NA</option>');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            eleedit.innerHTML = eleedit.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }

    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }

    }

    // useEffect(() => {
    //     optionaudio()
    //   }, [])


      const changeuserfun = async(e) =>{

        setUploaduserid(e)
        optionaudio(e)


      }

    

  
    const optionforusername = async (e) => {
        try{
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllAdmindrop", {
          method: 'POST',
          // headers: {
          //     'Content-Type': 'application/json'
          // },
    
          headers: {
            'Content-Type': 'application/json',
    
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
    
          // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
       
        var elelist = document.getElementById('userselectid');
        elelist.innerHTML = ""+('<option value="">NA</option>');
    
        for (var i = 0; i < json.length; i++) {
          // POPULATE SELECT ELEMENT WITH JSON.
          elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['username'] + '">' + json[i]['username'] +  '</option>';
        }
    
    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }
    
    
    
      }
    
      useEffect(() => {
        optionforusername()
      }, [])

    


  return (
    <>
    
    <div className='row'>
        <div className='col-md-3'>
               <div className='row'>
                       <form onSubmit={Ringgroupsubmitfun}>
                                    <div className='col-md-12'>
                                    <div className='card-header text-center bg-dark text-white p-2 ms-1'>Ring-Group</div>
                                        <div className='card p-2 ms-1'>
                                        <div className="mb-2 mt-3">
                                            <label>User Name:</label>
                                            <select id="userselectid" className="form-select"
                                               //  onChange={(e) => setUploaduserid(e.target.value)} 
                                               onChange={(e) => changeuserfun(e.target.value)} 

                                            
                                            required>                                           
                                            </select>
                                        </div>
                                        <div className='mt-2'>
                                            <label>Group Name</label>
                                            <input type="text" className='form-control'
                                            value={groupname}
                                            onChange={(e) => setGroupname(e.target.value)}
                                            
                                            required/>
                                        </div>
                                        <div className='mt-2'>
                                            <label>Group Description</label>
                                            <input type="text" className='form-control' 
                                             value={groupdes}
                                             onChange={(e) => setGroupdes(e.target.value)}
                                            
                                            required/>
                                        </div>
                                        <div className='mt-2'>
                                            <label>Strategy</label>
                                            <select id="strategyid" className="form-select"
                                                value={strategy}
                                                onChange={(e) => setStrategy(e.target.value)} 
                                                required> 
                                                 <option value="">Select</option> 
                                                <option value="RINGALL">RINGALL</option>   
                                                <option value="RANDOM">RANDOM</option> 
                                                                                          
                                            </select>
                                        </div>
                                        
                                        <div className="mt-2">   
                                            <label>RingBack</label>                                      
                                            <select id="ringbackaudioid" className="form-select" name="audio"
                                            
                                            value={ringbackaudio}
                                            onChange={(e) => setRingbackaudio(e.target.value)} 
                                            
                                            required>                                               
                                            </select>
                                            
                                        </div>
                                        <div className="mt-2">   
                                            <label>Timeout</label>                                      
                                            <select id="timeoutaudioid" className="form-select" name="audio"
                                            value={timeoutaudio}
                                            onChange={(e) => setTimeoutaudio(e.target.value)}                                             
                                            required>                                               
                                            </select>
                                            
                                        </div>
                                       
                                       
                                        <div className='mt-2'>
                                            <label>Active</label>
                                            <select id="activeid" className="form-select"
                                                value={active}
                                                onChange={(e) => setActive(e.target.value)} 
                                                required> 
                                                <option value="true">TRUE</option>
                                                <option value="false">FALSE</option>                                               
                                            </select>
                                        </div>
                                        <div className='mt-3'>
                                            
                                            <input type="submit" className="btn btn-primary btn-sm form-control text-white" />
                                        </div>
                                        </div>
                                    
                                    </div>
                                    <div className='col-md-6'>
                                
                                </div>

                       </form>

                        

               </div>
        </div>
        <div className='col-md-9'>
        <div className=' text-black'>
                                <h4 className='text-center card-header mt-2'>Existing RingGroup</h4>

                                <div className="table-responsive mt-3 text-nowrap">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>S.N</th>
                                                <th>UserName</th>
                                                <th>Name</th>
                                                <th>Desc</th>
                                                <th>Date</th>
                                                
                                                <th>Status</th>
                                                
                                                <th>Strategy</th>
                                                <th>RingBack</th>
                                                <th>TimeOut</th>
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                           {posts}
                                        </tbody>
                                        
                                       
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage"
                                        //  onChange={onInputChange} 
                                         />

                                </div>
                            </div>




                            <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



        </div>
    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Ringgroup