import React from "react";
import { FaDownload } from 'react-icons/fa';



const AudioDownloadButton = ({ audioUrl, audioName }) => {
   const handleDownload = () => {
  //try{

 

     const link = document.createElement('a');
     link.href = audioUrl;
    link.target = '_blank'; 
     link.download = audioName;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
  //}catch (error) {
    // Handle errors and update the state
    //this.setState({ error });
   // console.log("Audio Download Error")
  //}



  };

   return <button onClick={handleDownload}><FaDownload /></button>;
 };

export default AudioDownloadButton;
