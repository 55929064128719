import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
// import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import AudioPlayer from './AudioPlayer';

import Swal from 'sweetalert2';




const UploadAudio = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Upload Audio"
    }, []);




    const deleteAudio = async (e, f) => {

        var formdata = new FormData()
        formdata.append('id', e)
        formdata.append('username', f)
        const response = await fetch(process.env.REACT_APP_IP + "/User/DeleteAudio", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json.msg)
        if (json.type === "OK") {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Success",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 1500
            })

        } else {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 1500
            })
        }

        getAllPosts()





    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        try {
            const id = e
            // alert(id)
            const response = await fetch(process.env.REACT_APP_IP + "/User/ApproveAudio", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    "id": id

                })

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });

            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 4500
                })
            }
            getAllPosts()
            setIsToggled(!isToggled);

        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }

    }


    const el = useRef();

    const [uploadFile, setUploadFile] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('file', uploadFile)
        formData.append('username', uploaduserid)

        const response = await fetch(process.env.REACT_APP_IP + "/User/AudioUpload", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        getAllPosts()

        el.current.value = "";



        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }








    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)


    const [camuser, setCamuser] = useState("");
    const searchsubmit =  (e) => {
        e.preventDefault();
        //setCamuser(e);
        getAllPosts()

    }


    const getAllPosts = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllAudio?pageNumber=${offset - 1}&&username=${camuser}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
            const data = res.data;
            const postData = getPostData(data)
            // Using Hooks to set value
            setAllPosts(postData)
            // setPageCount(Math.ceil(data.length / postsPerPage))
            setPageCount(Math.ceil(data.totalPages))
        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])



    const [uploaduserid, setUploaduserid] = useState("NA");
    const optionforusername = async (e) => {
        try {
            const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const json = await respp.json()
            console.log(json.length)

            var elelist = document.getElementById('userselectid');
            elelist.innerHTML = "" + ('<option value="">NA</option>');

            for (var i = 0; i < json.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + json[i]['username'] + '">' + json[i]['username'] + '</option>';
            }


        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }


    }

    useEffect(() => {
        if (localStorage.getItem('roles') === 'ROLE_ADMIN') {
            optionforusername()
        }

    }, [])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>
                <tbody>
                    {data.content.map((page, index) => {
                        return (
                            <tr key={page.id}>
                                <td>{(offset - 1) * 5 + index + 1}</td>
                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                    <td>{page.userid}</td>
                                    : ""}
                                <td>{page.audioname}</td>
                                <td>{page.approve}</td>
                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                    <td> <label className="toggle-switch">
                                        <input type="checkbox" checked={page.approve === "Approved"} onChange={() => onToggle(page.id)} />
                                        <span className="switch" />
                                    </label></td> : ''}
                                <td>
                                    {/* <ReactAudioPlayer
                                                                src={process.env.REACT_APP_REC_OBD+page.userid+'/'+page.audioname}

                                                                controls /> */}

                                    <AudioPlayer
                                        src={process.env.REACT_APP_REC_LINK + page.userid + '/' + page.audioname}
                                    />


                                </td>




                                <td>
                                    <i className="far fa-trash-alt mx-2 btn btn-danger "
                                        onClick={() => deleteAudio(page.id, page.userid)}></i>

                                </td>




                            </tr>
                        );
                    })}
                </tbody>


            </>

        )

    }


    return (

        <>




            <div className='row  ms-1 me-1'>




                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
                <div className='col-md-3 col-12 text-black'>


                    <form className='card p-1 mybgcolor ms-1' style={{ minHeight: "100px", marginTop: "5px" }}
                        onSubmit={uploadaudioonsubmit}>
                        <h4 className='card-header text-center'>Upload Audio</h4>


                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                            <div className="mb-2">
                                <label>User Name:</label>
                                <select id="userselectid" className="form-select"
                                    onChange={(e) => setUploaduserid(e.target.value)} required>
                                </select>
                            </div>
                            : ''}

                        <div className="mb-3 mt-3">

                            <input className=" form-control form-control-sm" ref={el}
                                id="formFileSm" name="file" type="file" onChange={onchangfun} required />
                        </div>



                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                    </form>

                </div>


                <div className="col-md-9 col-12">

                    <div className='row mt-1'>
                        <motion.div
                            initial={{ x: -1200 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.5 }}
                        >

                            {/* <AddCampaignform /> */}


                            < div className='col-md-12 col-12'>

                                <div className=' ' style={{ minHeight: "50px" }}>

                                    <div className='card p-2'>
                                        <h2 className='card-header text-black text-center'>Existing Audio</h2>

                                        <div className="table-responsive ">

                                            <table className="table table-hover table-bordered checkoddata ignoreline">
                                                <thead className="bg-darkblue text-white">
                                                    <tr>
                                                        <th>S.N</th>
                                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                                            <th>UserName</th>
                                                            : ""}
                                                        <th>Audio Name</th>
                                                        <th>Approve Status</th>
                                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ? <th>Approve</th> : ''}
                                                        <th>Play</th>

                                                        <th>Delete</th>


                                                    </tr>
                                                </thead>
                                                {posts}
                                            </table>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <form onSubmit={searchsubmit}>
                                                        <input type="text" className="form-control" name="serachuser"
                                                            id="serachuserid" value={camuser}
                                                            onChange={(e) => setCamuser(e.target.value)}
                                                            placeholder="Search  By Username" />
                                                    </form>
                                                </div>
                                                <div className='col-md-3'>
                                                    <form
                                                    // onSubmit={noop}
                                                    >
                                                        <input type="number" className="form-control" id="gotopage" aria-describedby="gotopage"
                                                            placeholder="gotopage" onChange={onInputChange} />

                                                    </form>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </motion.div>

                    </div>


                    <div className="row">
                        <div className="col-md-3 col-12"></div>

                        <div className="col-md-6 col-12 mb-5">


                            <ReactPaginate
                                previousLabel={<i className="fa-solid fa-angles-left"></i>}
                                nextLabel={<i className="fa-solid fa-angles-right"></i>}
                                breakLabel={".."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />


                        </div>

                    </div>



                </div>




            </div>
        </>
    )


}


export default UploadAudio
