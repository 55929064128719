import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const AddIvr = () => {

 // const [postsPerPage] = useState(5);
 const [offset, setOffset] = useState(1);
 const [posts, setAllPosts] = useState([]);
 const [pageCount, setPageCount] = useState(0)

 const getAllPosts = async () => {
    try{
     const res = await axios.get(process.env.REACT_APP_IP+`/User/AllObdIvrpage?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
    
     const data = res.data;
     const postData = getPostData(data)
     // Using Hooks to set value
     setAllPosts(postData)
     // setPageCount(Math.ceil(data.length / postsPerPage))
     setPageCount(Math.ceil(data.totalPages))
    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }
 }
 
 useEffect(() => {
    getAllPosts()
}, [offset])


// deleteCampaign
const DeleteRinggroup = async (e) => {

    try{
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteObdIvr", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
   const json = await response.json()
   console.log(json.msg)
   
   if (json.type === "OK") {
    // props.showAlert(json.msg, "success");
    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Success',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 2500
    })
} else {
    //props.showAlert(json.msg, "danger");
    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 4500
    })
}
    
    getAllPosts()
    

} catch (error) {
    console.error('Error In DeleteRinggroup ', error);
  }


}


 const MenuCreate = () =>{


 }



const onToggle = async (e) => {

    try{
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/ActiveAssignCallpatch", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
           // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }
    getAllPosts()
   // setIsToggled(!isToggled);
} catch (error) {
    console.error('Error In onToggle ', error);
  }

}


const getPostData = (data) => {
    return (
        <>
          

                                        {data.content.map((page,index) => {
                                            return (
                                                <tr key={page.id}>
                                                      <td>{(offset - 1)*5+index+1}</td> 
                                                    <td>{page.userid}</td>
                                                    <td>{page.ivrname}</td>
                                                    <td>{page.ivrdesc}</td>
                                                    <td class="text-center">
                                                          <Link to="/Createmenu" className='btn btn-primary btn-sm ' 
                                                                state={ {id: page.id,ivrname: page.ivrname,username:page.userid}}>
                                                               <i className="fa  fa-edit" aria-hidden="true" ></i>
                                                              
                                                          
                                                          </Link>

                                                      

                                                    </td>

                                                    <td>{page.welcomeaudioname}</td>
                                                    <td>{page.exitaudioname}</td>


                                                    <td>{page.uploaddate}</td>

                                                    <td>

                                                        <label className="toggle-switch">
                                                            <input type="checkbox" checked={page.active} 
                                                            onChange={() => onToggle(page.id)}
                                                             />
                                                            <span className="switch" />
                                                        </label>

                                                    </td>
                                                    <td>{page.action}</td>
                                                    <td>{page.dtmflength}</td>
                                                    <td>{page.dtmfwait}</td>
                                                    <td>{page.dtmfretry}</td>
                                                    
                                                   
                                                    
                                                    <td>
                                                        <button className=" mx-2 btn btn-danger" 
                                                        onClick={() => DeleteRinggroup(page.id)}
                                                        >
                                                            <i className="fa fa-trash" ></i>
                                                        </button>

                                                         {/* <i className="fa fa-trash mx-2" onClick={() => deleteCampaign(page.id)}></i> */}
                                                         
                                                         
                                                         </td>




                                                </tr>
                                            );
                                        })}
                                    
        </>

    )

}


 const onInputChange = (event) => {

     const offset = Math.ceil(event.target.value);
     setOffset(offset);

 }
   
 
      const[ivrname,setIvrname] =  useState("");
      const[ivrdesc,setIvrdesc] =  useState("");
      const[audioid,setAudioid] =  useState("");
      const[action,setAction] =  useState("Hangup");      
      const [exitaudioid, setExitaudioid] = useState("");

    const [dtmflength, setDtmflength] = useState(1);
    const[active,setActive] =  useState(true);
    const[dtmfwait,setDtmfwait] =  useState(5);
    const[dtmfretry,setDtmfretry] =  useState(1);
    const[strategy,setStrategy] =  useState("");
    const [uploaduserid, setUploaduserid] = useState("NA");
    


     const Addivrsubmitfun = async(event) =>{
        event.preventDefault();

        // alert(
        //     ">>>>>>uploaduserid>>>"+uploaduserid+
        //     ">>>>>>ivrdesc>>>"+ivrdesc+
        //     ">>>>>>ivrname>>>"+ivrname+
        //     ">>>>>>welcome>>>"+audioid+
        //     ">>>>>>exitaudioid>>>"+exitaudioid+
        //     ">>>>>>action>>>"+action+
        //     ">>>>>>dtmflength>>>"+dtmflength+
        //     ">>>>>>dtmfwait>>>"+dtmfwait+
        //     ">>>>>>dtmfretry>>>"+dtmfretry+
        //     ">>>>>>active>>>"+active     
        // )

        var formdata = new FormData()
        formdata.append('userid', uploaduserid)
        formdata.append('ivrname', ivrname)
        formdata.append('ivrdesc', ivrdesc)
        formdata.append('welcomeaudioid', audioid)
        formdata.append('exitaudioid', exitaudioid)
        formdata.append('action', action)
        formdata.append('dtmflength', dtmflength)
        formdata.append('dtmfwait', dtmfwait)
        formdata.append('dtmfretry', dtmfretry)
        formdata.append('active', active)
        
       
        

    const response = await fetch(process.env.REACT_APP_IP+"/User/AddObdIvr", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body:formdata
        // body: JSON.stringify({
           
        // })

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }

    getAllPosts()

     }

    const optionaudio = async (e) => {
        var formdata = new FormData()
        formdata.append('username', e)
        //alert(">>>>"+e)
        const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllAudioSelect", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const jsona = await resppe.json()
        //console.log(jsona.length)

        // var ele = document.getElementById('groupid');
        // ele.innerHTML = ""+('<option value="NA">NA</option>');
        
        // for (var l = 0; l < jsona.length; l++) {
        //     // POPULATE SELECT ELEMENT WITH JSON.
        //     ele.innerHTML = ele.innerHTML +
        //         '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['name'] + '</option>';
        // }


        var eleedit = document.getElementById('audioid');
        eleedit.innerHTML = ""+('<option value="NA">NA</option>');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            eleedit.innerHTML = eleedit.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }

        var exitaudioidvar = document.getElementById('exitaudioid');
        exitaudioidvar.innerHTML = ""+('<option value="NA">NA</option>');
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            exitaudioidvar.innerHTML = exitaudioidvar.innerHTML +
                '<option  value="' + jsona[l]['id'] + '">' + jsona[l]['audioname'] + '</option>';
        }


        

    }

    // useEffect(() => {
    //     optionaudio()
    //   }, [])


      const changeuserfun = async(e) =>{

        setUploaduserid(e)
        optionaudio(e)
       // optionagent(e)


      }


      const optionagent = async (e) => {
        var formdata = new FormData()
        formdata.append('username', e)
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllcallpatchbyusername", {
          method: 'POST',
          // headers: {
          //     'Content-Type': 'application/json'
          // },
    
          headers: {
           // 'Content-Type': 'application/json',
    
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          },
          body: formdata
    
          // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
       
        var elelist = document.getElementById('agentid');
        elelist.innerHTML = ""+('<option value="">NA</option>');
    
        for (var i = 0; i < json.length; i++) {
          // POPULATE SELECT ELEMENT WITH JSON.
          elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['agentnumber'] + ' | '+json[i]['agentname'] +  '</option>';
        }
    
    
    
    
    
      }
    

  
    const optionforusername = async (e) => {

        try{
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllAdmindrop", {
          method: 'POST',
          // headers: {
          //     'Content-Type': 'application/json'
          // },
    
          headers: {
            'Content-Type': 'application/json',
    
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
    
          // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
       
        var elelist = document.getElementById('userselectid');
        elelist.innerHTML = ""+('<option value="">NA</option>');
    
        for (var i = 0; i < json.length; i++) {
          // POPULATE SELECT ELEMENT WITH JSON.
          elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['username'] + '">' + json[i]['username'] +  '</option>';
        }
    
    
        } catch (error) {
            console.error('Error in  optionforusername ', error);
          }
    
    
      }
    
      useEffect(() => {
        optionforusername()
      }, [])

      const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };


  return (
    <>
    
    <div className='row'>
            <div className='col-md-2'>
            </div>
        <div className='col-md-8'>
              
                       <form onSubmit={Addivrsubmitfun}>
                       
                                   
                            <div className='card-header text-center bg-dark text-white '>Add-IVR</div>
                                <div className='card p-4'>
                                <div className='row'>
                                  
                                 






                                    <div className='col-md-6'>
                                        <div className="mb-2 mt-1">
                                            <label>User Name:</label>
                                            <select id="userselectid" className="form-select"
                                               //  onChange={(e) => setUploaduserid(e.target.value)} 
                                               onChange={(e) => changeuserfun(e.target.value)} 

                                            
                                            required>                                           
                                            </select>
                                        </div>


                                        <div className='mt-1'>
                                            <label>IVR Name</label>
                                            <input className="form-control" type="text"
                                            
                                            value={ivrname}
                                            onChange={(e) => setIvrname(e.target.value)} 
                                            
                                            />
                                           
                                        </div>
                                        <div className='mt-1'>
                                            <label>IVR Desc</label>
                                            <input className="form-control" type="text"
                                            
                                            value={ivrdesc}
                                            onChange={(e) => setIvrdesc(e.target.value)} 
                                            
                                            />
                                           
                                        </div>

                                        <div className='mt-2'>
                                            <label>Welcome Audio</label>
                                            <select id="audioid" className="form-select"
                                               //  onChange={(e) => setUploaduserid(e.target.value)} 
                                               onChange={(e) => setAudioid(e.target.value)} 

                                            
                                            required> 
                                             </select>
                                           
                                        </div>
                                        <div className='mt-2'>
                                            <label>Action</label>
                                            <select id="activeid" className="form-select"
                                                value={action}
                                                onChange={(e) => setAction(e.target.value)} 
                                                required> 
                                                <option value="Hangup">Hangup</option>
                                                <option value="Dtmf">Dtmf</option>                                               
                                            </select>
                                        </div>
                                       
                                       
                                        
                                       
                                       
                                
                                      
                                        </div>
                                        <div className='col-md-6'>
                                   
                                        <div className='mt-1'>
                                            <label>Exit Audio</label>
                                            <select id="exitaudioid" className="form-select"
                                               //  onChange={(e) => setUploaduserid(e.target.value)} 
                                               onChange={(e) => setExitaudioid(e.target.value)} 

                                            
                                            required> 
                                             </select>
                                        </div>

                                        <div className='mt-1'>
                                            <label>Dtmf max length</label>
                                            <input className="form-control" type="number" min="0" max="9"
                                            
                                            value={dtmflength}
                                            onChange={(e) => setDtmflength(e.target.value)} 
                                            
                                            />
                                           
                                        </div>
                                       
                                    <div className='mt-2'>
                                            <label>DTMF Wait Time</label>
                                            <input className="form-control" type="number" min="1" max="40"
                                            
                                            value={dtmfwait}
                                            onChange={(e) => setDtmfwait(e.target.value)} 
                                            
                                            />
                                        </div>
                                        
                                       
                                        <div className="mt-2">   
                                            <label>DTMF Retry</label>                                      
                                            <input className="form-control" type="number" min="1" max="5"
                                            value={dtmfretry}
                                            onChange={(e) => setDtmfretry(e.target.value)} 


                                            />
                                            
                                        </div>
                                    <div className='mt-2'>
                                            <label>Active</label>
                                            <select id="activeid" className="form-select"
                                                value={active}
                                                onChange={(e) => setActive(e.target.value)} 
                                                required> 
                                                <option value="true">TRUE</option>
                                                <option value="false">FALSE</option>                                               
                                            </select>
                                        </div>
                                       
                                   </div>
                                   </div>  
                                   <div className='mt-3 mb-3'>
                                            
                                            <input type="submit" className="btn btn-primary btn-sm form-control text-white" />
                                        </div>
                                    </div>
                                    
                                    

                       </form>

                        

               
        </div>
        <div className='col-md-12 ms-1'>
        <div className=' '>
                                <h4 className='text-center card-header bg-dark mt-2 text-white '>Existing IVR</h4>

                                <div className="table-responsive mt-1 text-nowrap pe-3 ps-3">

                                    <table className="table table-hover table-bordered ">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>S.N</th>
                                                <th>UserName</th>
                                                <th>IvrName</th>
                                                <th>IvrDesc</th>
                                                <th>Create/View Menu</th>
                                                <th>welcomeaudioname</th>
                                                <th>exitaudioname</th>
                                                <th>Date</th>
                                                <th>Active</th>
                                                <th>Action</th>
                                                <th>Dtmflength</th>
                                                <th>Dtmfwaitsec</th>
                                                <th>DtmfRetry</th>
                                                
                                                
                                                
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody className='p-3'>
                                           
                                                {posts}
                                            
                                        </tbody>
                                        
                                       
                                    </table>
                                    Goto Pageno:<input type="number" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage"
                                        //  onChange={onInputChange} 
                                         />

                                </div>
                            </div>




                            <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



        </div>
    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default AddIvr