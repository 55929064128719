import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';
import ReactAudioPlayer from 'react-audio-player';
import Swal from 'sweetalert2';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { RotatingLines } from 'react-loader-spinner';
import { FiDownload } from 'react-icons/fi';
import AudioPlayer from './AudioPlayer';
import { FaDownload } from 'react-icons/fa';

import AudioDownloadButton from './AudioDownloadButton';


const Filterreportdownload = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Filter Report"
 }, []);
 const tdStyle = {
  padding: '10px', // Example: Adjust padding
  border: '1px solid #ccc', // Example: Add border
  backgroundColor: '#f0f0f0', // Example: Set background color
  color: '#333', // Example: Set text color
};

  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [campaignname,setCampaignname] = useState("ALL");

  const [keypress,setKeypress] = useState("1");

  const [opennav, setopennav] = useState();
  const[listname,setList] = useState();


  const setstartdate = (event) => {
      //alert(event)
    setstartdateval(event)
    var sttt=dateFormat(event, "yyyy-mm-dd")

 

  setsddate(sttt)
  


}

const filterreportsubmit =  async (event) => {
    event.preventDefault();
}

const testdownload = async (e1,e2) => {
  try {
    const response = await fetch(process.env.REACT_APP_IP + `/User/download?folderandname=${e1}`, {
        method: 'GET',
        headers: {
           // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
           "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
    });

    if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = e2;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } else {
        console.error('Error downloading CSV:', response.status);
        setDownloadtrue(false)
    }
    setDownloadtrue(false)
} catch (error) {
    console.error('Error downloading CSV:', error);
    setDownloadtrue(false)
}

}

const[temptoken , setTemptoken] = useState();

const userinfotoken = async (e) => {
  setTemptoken('')
  
  const respp = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
  })
  const json = await respp.json()
  setTemptoken(json.userpro.tempp)
}

useEffect(() => {
  userinfotoken()
}, [])



const optionforlist = async (e) => {
  const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllcampname", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
          'Content-Type': 'application/json',

          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
  })
  const json = await respp.json()
  console.log(json.length)

  var elelist = document.getElementById('campnameid');

 if(localStorage.getItem('roles') === 'ROLE_ADMIN')
{
    for (var i = 0; i < json.length; i++)
    {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['id'] + '">' + json[i]['username'] +' | '+ json[i]['campname'] + '</option>';
    }
}else{

      for (var i = 0; i < json.length; i++)
      {
          // POPULATE SELECT ELEMENT WITH JSON.
          elelist.innerHTML = elelist.innerHTML +
              '<option  value="' + json[i]['id'] + '">' + json[i]['campname'] + '</option>';
      }
}  

}
// useEffect(() => {
//   optionforlist()
// }, [])

const setenddate = (event) => {
    setEnddateval(event)
    var enddd=dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

}

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

const[downloadtrue ,setDownloadtrue] =useState(false)

  const filterreportdownload =async() =>{

    setDownloadtrue(true)

    // if(campaignname==='ALL')
    // {
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'error',
    //     title: 'Select Only 1 Campaign',
    //     background: '#e9ecef',
    //    // title: 'Done Successfully ',
    //    //background: primary,
    //     showConfirmButton: false,
    //     timer: 4500
    //   })
    //   setDownloadtrue(false)
    //   return;
    // }


    //process.env.REACT_APP_IP+`/User/checkreport?pageNumber=${offset - 1}&&
    //size=10&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&campid=${campaignname}`

    try {
      const response = await fetch(process.env.REACT_APP_IP + `/User/reportdownloadAnswer?startdate=${sddate}&&enddate=${eddate}`, {
          method: 'GET',
          headers: {
             // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
             "Authorization": `Bearer ${localStorage.getItem('token')}`
          },
      });

      if (response.status === 200) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = 'report.csv';
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
      } else {
          console.error('Error downloading CSV:', response.status);
          setDownloadtrue(false)
      }
      setDownloadtrue(false)
  } catch (error) {
      console.error('Error downloading CSV:', error);
      setDownloadtrue(false)
  }








      // window.open(process.env.REACT_APP_IP + `/User/reportdownloadbycampid`+`?campid=`+campaignname+`&temptoken=`+temptoken+
      // `&startdate=`+dateFormat(startdate, "yyyy-mm-dd")+`&enddate=`+dateFormat(enddate, "yyyy-mm-dd")+`&keypress=`+keypress);

  }

 
  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
        <div className='row'>
        <motion.div
    // style={pageStyle}
    // initial={{ x: 300, opacity: 0 }}
    // // animate={{ x: 0, opacity: 1,rotate: 360}}
    // animate={{ x: 0, opacity: 1}}
    // exit={{ rotate: 360}}
    // transition={{
    //   delay: 1,
    //   x: { type: "spring", stiffness: 100 },
    //   default: { duration: 2 },
    // }}
    // variants={pageVariants}
    // transition={pageTransition}
    // initial={{ scale: 0 }}
    // animate={{ scale: 1 }}
    // transition={{ duration: 1.5 }}

    initial={{ x: -1200 }}

    animate={{ x: 0 }}
    transition={{ duration: 0.5}}

  >
          {/* <div className="table-responsive text-nowrap"> */}
          <div className=" text-nowrap">
            <table className="table table-hover table-bordered">
            <thead className="bg-darkblue text-white">
                <tr>
                  {/* <th>ID</th> */}
                  <th>Customer Number</th>
                  <th>Agent Number</th>
                  <th>Campaign</th>
                  <th></th>
                  <th></th>
                  <th>Dialtime</th>
                  <th>AnswerTime</th>
                  <th>HangTime</th>
                  <th>Duration</th>
                  <th>TotalDuration</th>
                  <th>PressedKey</th>

                </tr>
              </thead>
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id}>
                      {/* <td>{page.id}</td> */}
                      <td>{page.custnumber}</td>
                      <td>{page.c2}</td>
                      {/* <td>{page.campaignid} | {page.campaign}</td> */}
                      <td>{page.campaign}</td>

                      <td>

                      <AudioPlayer src={process.env.REACT_APP_REC_OBD+page.folder+'/'+page.recordingname} />

                                

                      </td>
                      <td>
                      {/* <AudioDownloadButton audioUrl={process.env.REACT_APP_REC_OBD+page.folder+'/'+page.recordingname}
                              audioName={page.recordingname}
                              />  */}

                              <button onClick={() => testdownload(page.folder+'/'+page.recordingname,page.recordingname)}> 
                              <FaDownload />
                              </button>
                             
                      </td>
                      <td>{page.dialtime}</td>
                      <td>{page.customeranswertime}</td>
                      <td>{page.customerhanguptime}</td>
                      <td>{page.duration}</td>
                      <td>{page.totalduration}</td>
                      <td>{page.pressedkey}</td>

                      

                    </tr>
                  );
                })}
              </tbody>
            </table>

            Goto Pageno:<input type="number" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
              placeholder="gotopage" onChange={onInputChange} />
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
          </motion.div>
        </div>
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP+`/User/checkreport?pageNumber=${offset - 1}&&
    size=100&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&campid=${campaignname}`,
     { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])



  return (

    <>
   
   
    <div className='row me-1 '>



    {/* <ul className="nav nav-tabs mx-3">
  <li className="nav-item">
      <NavLink className="nav-link"  to="/Report">All Report</NavLink>
  </li>
  <li className="nav-item">
  <NavLink className="nav-link"  to="/FilterReport">Filter Report</NavLink>
  </li>
  
</ul> */}

      <div>
        <div className={downloadtrue ? "text-center mt-5" : "text-center mt-5 hide"}>
            <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="200"
                visible={true}
              />

        </div>
               

        <div className={downloadtrue ? "row hide" : "row "}>
        <div className='col-md-3'>
             <div className='card  mt-1 ms-1'>
                 <form onSubmit={filterreportsubmit}>
                     <div className='card-header text-center'>Filter Report</div>
                     {/* <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                        <select className="form-select" name="campaignname" 
                            onChange={(e) => setCampaignname(e.target.value)} >
                            <option value="All">ALL</option>
                           
                        </select>

                    </div> */}
                   

                    <div className="mb-1 mt-1">
                        <label htmlFor="campaignname" >Start Date</label>
                       
                        <DatePicker className="input-group date" onChange={setstartdate} value={startdate} />
                        {/* <DatePicker className="form-control" onChange={setstartdate} value={startdate} /> */}

                    </div>

                    <div className="mb-1 mt-1">
                        <label htmlFor="campaignname" >End Date</label>
                       
                        <DatePicker className="input-group date" onChange={setenddate} value={enddate} />

                    </div>

                    <div className="d-grid gap-2 mb-2 mt-2">
                         {/* <input className="btn btn-primary " type="submit" value="Submit" /> */}

                         {/* <input className="btn btn-primary " onClick={filterreportdownload} type="button" <FiDownload />value="Get-CSV" /> */}
                         <button className="btn btn-primary " onClick={filterreportdownload}> <FiDownload className='me-1' /> Get-CSV</button>

                    </div>
                    </form>

             </div>
             
                     
                     
                   

             



        </div>
        
        <div className='col-md-9 mt-2'>
               
                {/* <h1>Filter Report</h1> */}
                <div className='card-header text-center'>Filter Report</div>
                {/* Display all the posts */}
                {posts}

                
                
              <div className='row'>
                <div className='col-md-3'>

                </div>
                <div className='col-md-6'>
                  <ReactPaginate
                    previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}
                    nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}

                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />

                </div>
              </div>


        </div>
        

</div>
      </div>




    </div>
    </>
  )
}

export default Filterreportdownload
