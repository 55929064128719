import React,{ useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate';


const Callback = () => {
    const [posts, setAllPosts] = useState([]);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0)
     //getAllPosts
     const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/AllCallBack?pageNumber=${offset - 1}&&size=6`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }
    useEffect(() => {
      //  getAllPosts()
    }, [offset])

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }
    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    }


    const getPostData = (data) => {
        return (
            <>

                <div className='row '>

                    {/* <AddCampaignform /> */}


                    < div className='col-md-12 col-12'>

                        <div className='card mt-1' >



                            <div className=''>
                                <div className='card-header text-black'>
                                    <h4 className='text-center'>Existing CallBack</h4>
                                </div>

                                <div className="table-responsive p-1">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>S.N</th>

                                                <th>CustomerNumber</th>

                                                <th>CallBack Time</th>
                                                <th>Flag</th>
                                                

                                                


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.content.map(page => {
                                                return (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>

                                                        <td>{page.custnumber}</td>
                                                        <td>{page.callbacktime}</td>
                                                        <td>{page.flag}</td>
                                                    
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage"
                                        onChange={onInputChange}
                                    />

                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </>

        )

    }
  return (
    <>
    
    
    <div className='row'>

<div className='col-md-12'>

    {posts}
    <div className="row mb-5">
        <div className="col-md-3 col-12">

        </div>

        <div className="col-md-6 col-12">
            <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                breakLinkClassName={"break-mef"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                forcePage={offset - 1}

                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}

            />
        </div>

    </div>

</div>

</div>

    
    
    
    
    </>
  )
}

export default Callback