import "./App.css";
import { useEffect, useState} from 'react'
import SideBar from "./components/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Messages from "./pages/Messages";
import FileManager from "./pages/FileManager";
import Analytics from "./pages/Analytics";
import AssigncallpatchAgent from "./pages/AssigncallpatchAgent";
import Ringgroup from "./pages/Ringgroup";
import Setting from "./pages/Setting";
import ChannelSetting from "./pages/ChannelSetting";
import AddCampaign from "./pages/AddCampaign" 
import Incoming from "./pages/Incoming";
import Callbackactive from "./pages/Callbackactive";

// import Login from "./pages/Login";
import Report from "./pages/Report";
import UploadAudio from "./pages/UploadAudio";
import FilterReport from "./pages/FilterReport";
import ChurnLeads from "./pages/ChurnLeads";
import Agentlog from "./pages/Agentlog";
import UploadNumber from "./pages/UploadNumber";
import AssignDid from "./pages/AssignDid";
import Createmenu from "./pages/Createmenu";

import Calllog from "./pages/Calllog";

import Filtercalllog from "./pages/Filtercalllog";
import Changepwd from "./pages/Changepwd";
import Ivr from "./pages/Ivr";
import AddIvr from "./pages/AddIvr";


import Realtime from "./pages/Realtime";

import Audio from "./pages/Audio";
import Group from "./pages/Group";
import Agent from "./pages/Agent";
import Break from "./pages/Break";
import Callback from "./pages/Callback";
import AddDisposition from "./pages/AddDisposition";
import AddAgent from "./pages/AddAgent";
import Profile from "./pages/Profile";
import Block from "./pages/Block";
import Filterreportdownload from "./pages/Filterreportdownload";

import Logout from "./pages/Logout";
function App() {
 

 
  return (
    <Router>
      
       <SideBar> 
        <Routes>
        {/* <Route path="/Login" element={<Dashboard />} /> */}
       
          <Route path="/Dashboard" element={<Dashboard commonProp="Dashboard" />} />
          <Route path="/" element={<Dashboard  commonProp="Dashboard"  />} />
          <Route path="/UploadAudio" element={<UploadAudio />} />
          <Route path="/Realtime" element={<Realtime  commonProp="Realtime" />} />
          <Route path="/AddDisposition" element={<AddDisposition />} />
          <Route path="/Callback" element={<Callback />} />
          <Route path="/Break" element={<Break />} />
          <Route path="/ChurnLeads" element={<ChurnLeads />} />
          <Route path="/Callbackactive" element={<Callbackactive />} />
          <Route path="/Incoming" element={<Incoming />} />
          <Route path="/AssignDid" element={<AssignDid />} />
          <Route path="/AddAgent" element={<AddAgent />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Block" element={<Block />} />
          <Route path="/Filterreportdownload" element={<Filterreportdownload />} />
          
          
          {/* <Route path="/Logout" element={<Logout />} /> */}
          <Route path="/Users" element={<Users />} />
          
        
          <Route path="/messages" element={<Messages />} />
         
          
         
          <Route path="/Ringgroup" element={<Ringgroup />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/ChannelSetting" element={<ChannelSetting />} />
          <Route path="/Changepwd" element={<Changepwd />} />
          <Route path="/CallLog" element={<Calllog />} />
        
          <Route path="/AddCampaign" element={<AddCampaign />} />
         
          <Route path="/Report" element={<Report />} />
          <Route path="/UploadNumber" element={<UploadNumber />} />
          

          <Route path="/FilterReport" element={<FilterReport />} />

          <Route path="/Agentlog" element={<Agentlog />} />
          <Route path="/AssigncallpatchAgent" element={<AssigncallpatchAgent />} />
          <Route path="/AddIvr" element={<AddIvr />} />
          <Route path="/Createmenu" element={<Createmenu />} />
          

               
          <Route path="/Audio" element={<Audio />} />
          <Route path="/Group" element={<Group />} />
          <Route path="/Agent" element={<Agent />} />
          <Route path="/FilterCallLog" element={<Filtercalllog />} />
          

          <Route path="*" element={<>?? </>} />
        </Routes>
       </SideBar> 
    </Router>
  );
}

export default App;
