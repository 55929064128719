import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';




const AddAgent = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Add Agent"
    }, []);

    const csvData = [
        ["Number"]
    ];

    const el = useRef();


    const [campaignname, setCampaignname] = useState();
    const [uploadFile, setUploadFile] = useState();
    const [listname, setListname] = useState();

    const [agentnumber, setAgentnumber] = useState();
    const [agentname, setAgentname] = useState();

    const [campselectid, setCampselectid] = useState("");



    const changeuserfun = async (e) => {
        //alert(e)
        // setCusername(e)
        setUploaduserid(e)
        // getAllCampaignforuser(e)


    }
    const getAllCampaignforuser = async (e) => {
        try {
            var formdata = new FormData()
            formdata.append('username', e)
            //alert(">>>>"+e)
            const resppe = await fetch(process.env.REACT_APP_IP + "/User/RestAllcampnamebyusername", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    //'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })

            const jsona = await resppe.json()
            //console.log(jsona.length) 
            // var ele = document.getElementById('audioivrid');

            var elelist = document.getElementById('campselectid');
            elelist.innerHTML = "" + ('<option value="">----Select------</option>');
            // elelist.innerHTML = elelist.innerHTML + ('<option value="All">All</option>');
            for (var i = 0; i < jsona.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + jsona[i]['campname'] + '">' + jsona[i]['campname'] + '</option>';
            }

        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }

    }


    const optionforlist = async (e) => {
        try {

            const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllcampname", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const json = await respp.json()
            console.log(json.length)

            var elelist = document.getElementById('campnameid');
            for (var i = 0; i < json.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + json[i]['id'] + '">' + json[i]['campname'] + '</option>';
            }



        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }


    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        try {
            const id = e
            // alert(id)
            const response = await fetch(process.env.REACT_APP_IP + "/User/ActiveAgentobd", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    "id": id

                })

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json);
            if (json.type === "OK") {
                // props.showAlert(json.msg, "success");
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    // iconHtml:'<b></b>',
                    title: json.msg,
                    //    title: 'Success',
                    background: '#e9ecef',
                    width: '250px',
                    height: '150px',
                    //size:'10px',
                    // title: 'Done Successfully ',
                    //background: primary,
                    showConfirmButton: false,
                    timer: 2500
                })
            } else {
                //props.showAlert(json.msg, "danger");
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: json.msg,
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    showConfirmButton: false,
                    timer: 2500
                })
            }
            getAllPosts()
            setIsToggled(!isToggled);
        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }

    }


    useEffect(() => {
        optionforlist()
    }, [])

    const [uploaduserid, setUploaduserid] = useState("NA");
    const optionforusername = async (e) => {
        try {
            const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const json = await respp.json()
            console.log(json.length)

            var elelist = document.getElementById('userselectid');
            elelist.innerHTML = "" + ('<option value="">NA</option>');

            for (var i = 0; i < json.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + json[i]['username'] + '">' + json[i]['username'] + '</option>';
            }


        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }


    }

    useEffect(() => {
        optionforusername()
    }, [])

    const deleteCampaign = async (e) => {
        try {
            var formdata = new FormData()
            formdata.append('id', e)
            const response = await fetch(process.env.REACT_APP_IP + "/User/DeleteAgent", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    // 'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json.msg)
            if (json.type === "OK") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Success",
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } else {

                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            }

            getAllPosts()


        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }


    }


    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }

    const addagentonsubmit = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData()
            //formData.append('file', uploadFile)
            // alert(">>>>"+campselectid)
            formData.append('userid', uploaduserid)
            formData.append('agentname', agentname)
            formData.append('agentnumber', agentnumber)
            // formData.append('campaignname', campselectid)



            const response = await fetch(process.env.REACT_APP_IP + "/User/AddDeleteAgentAction", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    // 'Content-Type': 'multipart/form-data',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formData

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            });
            const json = await response.json()
            console.log(json)
            if (json.type === "OK") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Success",
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 1500
                })

            } else {

                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'error',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    text: json.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            getAllPosts()
            setAgentnumber('')
            setAgentname('')
            //setCampselectid('')

            // el.current.value = "";



            //alert(">>>>"+e.files[0]);
            // alert(uploadFile);

        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }
    }



    const downloadsample = async (e) => {
        e.preventDefault();

        const response = await fetch(process.env.REACT_APP_IP + "/User/api/foo.csv", {
            method: 'GET',
            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        });
        const json = await response.json()
        // console.log(json)
        props.showAlert(" Downloaded Successfully ", "success");
        getAllPosts()
    }







    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const [camuser, setCamuser] = useState("");
    const searchsubmit =  (e) => {
        e.preventDefault();
        //setCamuser(e);
        getAllPosts()

    }

    const getAllPosts = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_IP + `/User/AllCallpatchAgent?pageNumber=${offset - 1}&&username=${camuser}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
            const data = res.data;
            const postData = getPostData(data)
            // Using Hooks to set value
            setAllPosts(postData)
            // setPageCount(Math.ceil(data.length / postsPerPage))
            setPageCount(Math.ceil(data.totalPages))
        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

                <tbody className='text-black'>
                    {data.content.map((page, index) => {
                        return (
                            <tr style={{ Color: 'black' }} key={page.id}>
                                <td>{(offset - 1) * 10 + index + 1}</td>


                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                    <td>{page.userid}</td>
                                    : ""}



                                {/* <td>{page.campid}</td> */}
                                <td>{page.agentname}</td>
                                <td>{page.agentnumber}</td>
                                <td>{page.lastcalltime}</td>
                                <td>

                                    <label className="toggle-switch">
                                        <input type="checkbox" checked={page.status === "Active"} onChange={() => onToggle(page.id)} />
                                        <span className="switch" />
                                    </label>

                                </td>
                                <td> <i className="far fa-trash-alt mx-2 btn btn-danger " onClick={() => deleteCampaign(page.id)} ></i></td>




                            </tr>
                        );
                    })}
                </tbody>


            </>

        )

    }





    return (

        <>




            <div className='row mt-1 me-1 '>




                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
                <div className='col-md-3 col-12 text-black'>


                    <form className='card mybgcolor p-2 mx-1'
                    
                    // style={{
                    //     minHeight: "400px", marginTop: "1px"
                    // }}
                    
                    onSubmit={addagentonsubmit}
                    
                    >
                        <h4 className=' card-header'>Add Agent</h4>

                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?


                            <div className="mb-2 mt-3">
                                <label>User Name:</label>
                                <select id="userselectid" className="form-select"

                                    // onChange={(e) => setUploaduserid(e.target.value)} 
                                    onChange={(e) => changeuserfun(e.target.value)}


                                    required>



                                </select>
                            </div>

                            : ''}

                        {/* {localStorage.getItem('roles') === 'ROLE_ADMIN' ?


                            <div className="mb-2">
                                <label>Campaign:</label>
                                <select id="campselectid" className="form-select"
                                    onChange={(e) => setCampselectid(e.target.value)} required>



                                </select>
                            </div>

                            : ''} */}




                        <div className="mb-1">
                            <label>Agent Name</label>
                            <input name="agentname" type="text" className=" form-control form-control-sm" value={agentname}
                                onChange={(e) => setAgentname(e.target.value)} required />


                        </div>

                        <div className="mb-1">
                            <label>Agent Number</label>
                            <input name="agentnumber" type="text" className=" form-control 
                         form-control-sm" value={agentnumber}
                                onChange={(e) => setAgentnumber(e.target.value)} required />


                        </div>


                        <input className="btn btn-primary mt-3 mb-3" type="submit" value="Submit" />






                    </form>

                </div>


                <div className="col-md-9 col-12" >

                    <div className='row mt-1 '>

                    <motion.div
                            initial={{ x: -800 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.5 }}
                        >

                            {/* <AddCampaignform /> */}


                            < div className='col-md-12 col-12' >

                                <div className='card mybgcolor ' style={{

                                }}>

                                    <div className='p-1'>
                                        <h2 className='card-header text-center '>Existing Agent</h2>

                                        <div className="table-responsive mt-1">

                                            <table className="table table-hover table-bordered checkoddata ignoreline">
                                                <thead className="bg-darkblue text-white" >
                                                    <tr>
                                                        <th>S.N</th>
                                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                                            <th>UserName</th>
                                                            : ""}
                                                        {/* <th>Campaign</th> */}
                                                        <th>Name</th>

                                                        <th>Number</th>
                                                        <th>LastCall</th>
                                                        <th>Active</th>
                                                        <th>Delete</th>


                                                    </tr>
                                                </thead>
                                                {posts}
                                            </table>
                                            <div className='row'>
                                    <div className='col-md-3'>
                                        <form onSubmit={searchsubmit}>
                                            <input type="text" className="form-control" name="serachuser"
                                                id="serachuserid" value={camuser}
                                                onChange={(e) => setCamuser(e.target.value)}
                                                placeholder="Search By User" />
                                        </form>
                                    </div>
                                    <div className='col-md-3'>
                                        <form 
                                       // onSubmit={noop}
                                        >
                                            <input type="number" className="form-control" id="gotopage" aria-describedby="gotopage"
                                                placeholder="gotopage" onChange={onInputChange} />

                                        </form>
                                    </div>

                                </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </motion.div>

                    </div>


                    <div className="row">
                        <div className="col-md-3 col-12"></div>

                        <div className="col-md-6 col-12 mb-5">


                            <ReactPaginate
                                previousLabel={<i className="fa-solid fa-angles-left"></i>}
                                nextLabel={<i className="fa-solid fa-angles-right"></i>}
                                breakLabel={".."}
                                breakClassName={"break-me"}
                                breakLinkClassName={"break-mef"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                forcePage={offset - 1}

                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}

                            />


                        </div>

                    </div>



                </div>




            </div>

        </>
    )


}


export default AddAgent
