import React, { useState } from 'react'
import Swal from 'sweetalert2';

const Block = () => {

    const[pastenumber,setpastenumber] = useState("");

    const addBlock = async (event) => {
        event.preventDefault();  
        
            var formdata = new FormData()            
            formdata.append('pastenumber', pastenumber)            
        const response = await fetch(process.env.REACT_APP_IP+"/User/AddBlock", {
            method: 'POST',         
            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body:formdata          
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        setpastenumber('')
       
    }

  return (
    <>

<div className='col-md-3 col-12 mt-1'>
<div className='card-header text-black'>
                        <h4>Block Number</h4>
        </div>
                <form className='card p-2' 
                // style={{
                //     backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)"
                //     , minHeight: "700px"

                // }}
                
                 onSubmit={addBlock}
                >
       
        <div className="mt-2 mb-2">
                            <textarea id="list" className="form-control" 
                                     name="pastenumber" placeholder='Paste Number with comma or space' rows={5}
                                             value={pastenumber} onChange={(e) => setpastenumber(e.target.value)} >
                                
                            </textarea>
                            
        </div>

        <input className="btn btn-primary mt-3 mb-3" type="submit" value="Submit" />

    </form>
   </div>
    
    
    
    </>
  )
}

export default Block