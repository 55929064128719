

import '../App.css';
import { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import backgroundImage from '../images/imagestbbb.jpg'

import { CSVLink } from 'react-csv';

export const Agent = (props) => {

    let history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getNotes();
            // eslint-disable-next-line
        } else {
            history('/Login');
        }
    }, [])

    const tableStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };

    useEffect(() => {
        document.title = "Home"
    }, []);


   
   const [amount , setAmount] = useState();
   
    

    const[totalcamp,settotalcamp] = useState(0)
    const totalcampfun = async () => {  
        try{
        const res = await axios.get(process.env.REACT_APP_IP+`/User/Counttotalcampaign`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        settotalcamp(data)

    } catch (error) {
        console.log(error);
      }
    
      }

      useEffect(() => {
        totalcampfun()
    }, [])


    const[activeagent,setactiveagent] = useState(0)
    const activeagentfun = async () => {  
        try{
        const res = await axios.get(process.env.REACT_APP_IP+`/User/CountActiveobdcallpatchagent`,
          { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    
        const data = res.data;
        setactiveagent(data)

    } catch (error) {
        console.log(error);
      }
    
      }

      useEffect(() => {
        activeagentfun()
    }, [])

    const [postsPerPage] = useState(10);
    const [postsPerPage2] = useState(10);
    const [offset, setOffset] = useState(1);
    const [offset2, setOffset2] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [posts2, setAllPosts2] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [pageCount2, setPageCount2] = useState(0)


    // const getAllPosts = async () => {
    //     const res = await axios.get(`http://localhost:8080/withouttokenhello`)
    //     const data = res.data;
    //     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    //     // For displaying Data
    //     const postData = getPostData(slice)

    //     // Using Hooks to set value
    //     setAllPosts(postData)
    //     setPageCount(Math.ceil(data.length / postsPerPage))
    // }

    
    const[credit , setCredit] = useState(0)
    const[creditconsume , setCreditconsume] = useState(0)

    const userinfo = async (e) => {

        try {
        const respp = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
      
            headers: {
                'Content-Type': 'application/json',
      
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
      
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        if (respp.status === 200) {
        const json = await respp.json()
        setCredit(json.userpro.voicecredit)
        setCreditconsume(json.userpro.voicecreditconsume)
       // console.log(json.length)
        }else{
            console.log("Error In /User/GetUserinfo");
        }
    } catch (error) {
        console.log(error);
      }
       
      }
      
      useEffect(() => {
        if (localStorage.getItem('token')) {
            userinfo()
        }
    }, [])


    const headers = [
        { label: "Caller", key: "CustomerNumber" },
        { label: "Source", key: "did" },
        { label: "Calltype", key: "type" },
        { label: "Agents Name", key: "agentname" },
        { label: "Agents number", key: "AgentNumber" },
        { label: "Start", key: "dialtime" },
        { label: "End", key: "agenthanguptime" },
        { label: "Duration", key: "totalduration" },
        { label: "Keypress", key: "pressedkey" },
        { label: "Dial Status", key: "dialstatus" }
    
    
      ];

    const[keypresseddata,setKeypresseddata] = useState([]);

    const getdata = async () => {

        const res = await axios.get(process.env.REACT_APP_IP + `/User/getOnlyKeypressed`,
          { 
            headers: 
            { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${localStorage.getItem('token')}` 
            } 
          }
          )
    
          // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_REC_LINK)
          // console.log(">>>>>>>>>>>rec>>>>>>>>"+process.env.REACT_APP_IP)
          
    
        //const datakk = res.data;
    
       // const json = await res.data
    
        //alert(JSON.stringify(res.data))
    
        //return JSON.stringify(res.data);
        setKeypresseddata(res.data)
    
    
      }
      
      //const data = reportdaat;
    
      useEffect(() => {
       // getdata();
      }, [])

    // const getAllPosts = async () => {

    //     if (localStorage.getItem('token')) {
    //         const res = await axios.get(process.env.REACT_APP_IP + `/User/getRecordList`,
    //             { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
    //         const data = res.data;
    
    //         if(offset>1)
    //         {   
    //             const slice = data.slice(postsPerPage*(offset-1) - 1, postsPerPage*(offset-1) - 1 + postsPerPage)
    //              const postData = getPostData(slice)
    
    //              //     // Using Hooks to set value
    //              setAllPosts(postData)
    //              setPageCount(Math.ceil(data.length / postsPerPage))
    
    //         }else{
    
    //             const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
    
    //             // For displaying Data
    //             const postData = getPostData(slice)
        
    //             //     // Using Hooks to set value
    //             setAllPosts(postData)
    //             setPageCount(Math.ceil(data.length / postsPerPage))
    //         }
    //     }


    // }


//     const getAllPosts2 = async () => {

//         if (localStorage.getItem('token')) {
//             try {
//         const res2 = await axios.get(process.env.REACT_APP_IP + `/User/getRecord`,
//             { 
//                 headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }
//              })
        
//         const data2 = res2.data;

//         if(offset2>1)
//         {
//           //  const slice2 = data2.slice(offset2 - 1, offset2 - 1 + postsPerPage2)

//             // For displaying Data
//           //  const postData2 = getPostData2(slice2)
    
//             //     // Using Hooks to set value
//           //  setAllPosts2(postData2)
//            //setPageCount2(Math.ceil(data2.length / postsPerPage2))

//             const slice2 = data2.slice(postsPerPage2*(offset2-1) - 1, postsPerPage2*(offset2-1) - 1 + postsPerPage2)
//              const postData2 = getPostData2(slice2)

//              //     // Using Hooks to set value
//              setAllPosts2(postData2)
//              setPageCount2(Math.ceil(data2.length / postsPerPage2))

//         }else{

//             const slice2 = data2.slice(offset2 - 1, offset2 - 1 + postsPerPage2)

//             // For displaying Data
//             const postData2 = getPostData2(slice2)
    
//             //     // Using Hooks to set value
//             setAllPosts2(postData2)
//             setPageCount2(Math.ceil(data2.length / postsPerPage2))
//         }
//     }catch (error) {
//         console.log(error);
//       }
        
//     }
// }

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const handlePageClick2 = (event) => {
        const selectedPage2 = event.selected;
        setOffset2(selectedPage2 + 1)
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
           // getAllPosts()
        }
    }, [])

    // useEffect(() => {
    //     if (localStorage.getItem('token')) {

    //        getAllPosts2()

    //         const timer = setInterval(async () => {

               

    //             getAllPosts2()
    
    //             return () => clearInterval(timer);
    //         }, 8000)
           

    //     }

    // }, [offset2])


    const { showAlert } = props

    // const getPostData2 = (data2) => {

    //     return (
    //         <>



    //             {/* <h2>This is home page</h2> */}

    //             {/* call */}


    //             <div className="col-sm-12 col-12 my-2">
    //                 <h5 className='text-center'>Pending Data List</h5>
    //                 <div className="table-responsive">
    //                     <table  className="table table-hover table-striped bg-darkblue text-white" >
    //                         <thead>
    //                             <tr>
    //                                 <th>ListName</th>
    //                                 {/* <th>Answer</th>
    //                                 <th>NoAnswer</th> */}
    //                                 <th>Pendingdata</th>
    //                                 <th>Dialeddata</th>
    //                                 <th>Totaldata</th>

    //                             </tr>
    //                         </thead>
    //                         <tbody >
    //                             {data2.map(page2 => {
    //                                 return (
    //                                     <tr key={page2._id}>
    //                                         <td>{page2.listname}</td>
    //                                         {/* <td>{page2.answer}</td>
    //                                         <td>{page2.noanswer}</td> */}
    //                                         <td>{page2.pending}</td>
    //                                         <td>{page2.dialed}</td>
    //                                         <td>{page2.totalStudents}</td>

    //                                     </tr>
    //                                 );
    //                             })}
    //                         </tbody>
    //                     </table>
    //                 </div>
    //             </div>



    //         </>




    //     )
    // }


    // const getPostData = (data) => {

    //     return (
    //         <>




    //             {/* <h2>This is home page</h2> */}

    //             {/* call */}
                



    //                 <div className="col-sm-12 col-12 my-2" >
    //                     <h5>Dialstatus Report</h5>
    //                     <div className="table-responsive">
    //                         <table className="table table-hover  table-striped  bg-darkblue text-white">
    //                             <thead>
    //                                 <tr>

    //                                     <th>Campaign</th>
    //                                     <th>List</th>
    //                                     <th>Answer</th>
    //                                     <th>NoAnswer</th>

    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {data.map(page => {
    //                                     return (
    //                                         <tr key={page.id}>

    //                                             <td>{page.campaign}</td>
    //                                             <td>{page.list}</td>
    //                                             <td>{page.dialeddata}</td>
    //                                             <td>{page.totaldatainlist}</td>

    //                                         </tr>
    //                                     );
    //                                 })}
    //                             </tbody>
    //                         </table>

    //                     </div>
    //                 </div>
                

    //         </>




    //     )
    // }




    return (



        <div className='me-2 ms-2'>
           


            {
                localStorage.getItem('token') ?

                    <div className="container-fluid  p-1">


                        {/* <h1>This is Home page</h1> */}

                        <div className="row mt-1">

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box ">
                                    <div className="inner">
                                        <h3>{activeagent}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-user"></i>
                                        </div>

                                        <p>Active Agent</p>

                                    </div>
                                      
                                    {/* <Link className="small-box-footer" to="/AddAgent" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link> */}
                               
                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                    

                                </div>
                            </div>

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box ">
                                    <div className="inner">
                                        <h3>{totalcamp}</h3>
                                        <div className="icon">
                                            <i className=" fa fa-sitemap"></i>
                                            {/* <i class="fa-solid fa-sitemap"></i> */}
                                        </div>

                                        <p>Total Campaign</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                                    
                                    
                                    {/* <Link className="small-box-footer" to="/AddCampaign" >More info<i className=" mx-2 fas fa-arrow-circle-right"></i></Link> */}
                               
                               
                                </div>
                            </div>
                            



                          


                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box ">
                                    <div className="inner">
                                        {/* <h3>{credit}</h3> */}
                                        <h3>NA</h3>
                                        <div className="icon">
                                            <i className=" fa fa-indian-rupee-sign"></i>
                                            {/* <i class="fa-solid fa-indian-rupee-sign"></i> */}
                                        </div>

                                        <p>Total Credit</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                               
                               
                                </div>
                            </div>

                            <div className="col-lg-3 col-6 text-white">

                                <div className="small-box ">
                                    <div className="inner">
                                        {/* <h3>{credit-creditconsume}</h3> */}
                                        <h3>NA</h3>
                                        <div className="icon">
                                            <i className=" fa fa-indian-rupee-sign"></i>
                                            {/* <i class="fa-solid fa-indian-rupee-sign"></i> */}
                                        </div>

                                        <p>Credit Remain</p>

                                    </div>

                                    {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                               
                               
                                </div>
                                </div>

                               


                               

                        {/* <div className='row'>
                         <div class="col-md-3 col-12">
                            <div class="panel panel-primary">
                                <div class="panel-heading">
                                    <div class="row">
                                        <div class="col-sm-3 col-3">
                                            <i class="fa fa-comments fa-5x"></i>
                                        </div>
                                        <div class="col-sm-9 col-9 text-right">
                                            <div class="huge">26</div>
                                            <div>New Comments!</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                        <a href="#" style={{backgroundColor: "#FF0000"}}>
                                            <div class="panel-footer"> */}
                                            {/* <a href="#" className="small-box-footer">More info 
                                            <i className="fas fa-arrow-circle-right"></i></a> */}
                                    
                                                {/* <span class="pull-left">View Details</span>
                                                <span class="pull-right"><i class="fa fa-arrow-circle-right"></i></span>

                                                <div class="clearfix"></div>
                                            </div>
                                        </a>

                                </div>
                                
                            </div>
                        </div>
                        </div> */}
                            



                        </div>
                        {/* Display all the posts */}
                        <div className="row" style={{ marginLeft: "-5px", marginRight: "-5px" }}>
                             <div className="col-sm-6 col-12 my-2" >
                                {/* {posts} */}

                                <div className="row">
                                    <div className="col-sm-2" ></div>
                                    <div className="col-sm-6" >

                                        {/* Using React Paginate */}
                                         {/* <ReactPaginate
                                            previousLabel={"previous"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} /> */}
                                    </div>
                                    <div className="col-sm-2" ></div>
                                </div> 

                             </div> 






                            <div className="col-sm-6 col-12 my-2" >
                                {/* {posts2} */}



                                {/* Using React Paginate */}

                                <div className="row">
                                    <div className="col-sm-2" ></div>
                                    <div className="col-sm-8" >
                                        {/* <ReactPaginate
                                            previousLabel={"previous"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={pageCount2}
                                            onPageChange={handlePageClick2}
                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} /> */}
                                    </div>

                                    <div className="col-sm-2" ></div>

                                </div>


                            </div>
                        </div>


                    </div> : <div>

                       
                    </div>

            }

        </div>





    )



}

export default Agent
