import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
//import DatePicker from 'react-date-picker';
import axios from 'axios'

import { motion } from 'framer-motion';

//import dateFormat from "dateformat";
//import { Button } from 'react-bootstrap';

const ChannelSetting = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  })


  const submiteditform = async (e) => {
    e.preventDefault();
    console.log(echannelid + ">>>>>>>" + eactive + ">>>>>>>>>" + estatus)
    var formdata = new FormData()
    formdata.append('id', echannelid)
    formdata.append('active', eactive)
    formdata.append('status', estatus)
    // formdata.append('name', eusername)
    formdata.append('userid', euserid)
    const response = await fetch(process.env.REACT_APP_IP + "/User/UpdateChannel", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        // 'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });

    console.log(response)
    ref.current.click();
    getAllPosts()

  }


  const noop = async (e) => {

    e.preventDefault();
  }
  const [username, setUsername] = useState("ALL");

  const optionforusername = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    //console.log(json.length)

    var elelist = document.getElementById('usernameiddropdown');

    elelist.innerHTML = "" + ('<option value="ALL">ALL</option>');
    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['id'] + '">' + json[i]['username'] + ' | ' + json[i]['username'] + '</option>';
    }





  }

  const [bulkuserid, setbulkuserid] = useState()
  const useroptionbulkupdate = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('usernameiddropdownbulk');
    elelist.innerHTML = "" + ('<option value="">NA</option>');

    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['id'] + '">' + json[i]['username'] + ' | ' + json[i]['username'] + '</option>';
    }





  }

  useEffect(() => {
    // optionforusername()
  }, [])

  const [channel, setchannel] = useState()
  const [suser, setSuser] = useState("")


  const [echannelid, setEchannelid] = useState()
  const [eactive, setEactive] = useState(1)
  const [estatus, setEstatus] = useState(0)

  const [euserid, seteuserid] = useState()
  const [eusername, seteusername] = useState()

  const [modelusername, setModelusername] = useState()

  const ref = useRef(null)
  const refbulk = useRef(null)
  const addchannelref = useRef(null)


  const [from, setFrom] = useState(1)
  const [to, setTo] = useState(2)


  const addChannel = async (e) => {

    addchannelref.current.click();
  }

  const addBulkChannel = async (e) => {
    useroptionbulkupdate()
    refbulk.current.click();

  }



  const submitbulkupdateform = async (e) => {
    e.preventDefault();
    console.log(">>>>from>>>" + from)
    console.log(">>>>to>>>" + to)
    console.log(">>>>to>>>" + bulkuserid)
    console.log(">>>>portstatus>>>" + estatus)
    console.log(">>>>>portactive>>>>>>>>" + eactive)


    refbulk.current.click();
    var formdata = new FormData()
    formdata.append('from', from)
    formdata.append('to', to)
    formdata.append('userid', bulkuserid)
    formdata.append('portstatus', estatus)
    formdata.append('portactive', eactive)
    const response = await fetch(process.env.REACT_APP_IP + "/User/UpdateBulkChannel", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        //  'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    getAllPosts()

  }



  const submitaddChannel = async (e) => {
    e.preventDefault();
    addchannelref.current.click();
    var formdata = new FormData()
    formdata.append('channel', channel)
    const response = await fetch(process.env.REACT_APP_IP + "/User/AddChannel", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        //  'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: json.msg,
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }

    getAllPosts()



  }
  const updateChannel = async (e) => {

    ref.current.click();
    //alert(">>>>>>>>>>"+e);
    const response = await fetch(process.env.REACT_APP_IP + "/User/getChannelById", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        "id": e

      })

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    // console.log(json);
    // console.log(json.campname);
    // console.log(json.campaign_mode);
    setEchannelid("")
    setEchannelid(json.id)
    setEactive("")
    setEactive(json.portactive)
    setEstatus("")
    setEstatus(json.portstatus)


    //seteuserid("")
    seteuserid(json.username)

    //seteusername("")
    seteusername(json.username)
    setModelusername(json.username)

    const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const jsona = await respp.json()

    var elelist2 = document.getElementById('usernameiddropdownl');

    //alert(">>>>>>>>>>>>"+json.userid);
    //  elelist2 = elelist2.empty()
    elelist2.innerHTML = "";
    elelist2.innerHTML = elelist2.innerHTML +
      ('<option  value="' + json.username + '">' + json.username + ' | ' + json.username + '</option>');

    //var newop;
    for (var i = 0; i < jsona.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist2.innerHTML = elelist2.innerHTML +
        ('<option  value="' + jsona[i]['id'] + '">' + jsona[i]['username'] + ' | ' + jsona[i]['username'] + '</option>');
    }

    console.log(eusername)
    // setEcampaignmode(json.campaign_mode)
    // setEcampaignid(json.id)
    // setestarttime(json.start_time)
    // setEendtime(json.end_time)
    // setEeselectaudio(json.audioid | json.audio_name)
    // setEeselectlist(json.listname)
    // setmaxtry(json.maxtry)

    //getAllPosts()
    //setIsToggled(!isToggled);




  }


  const filterreportsubmit = async (event) => {
    event.preventDefault();

    console.log(">>>>username>>>>>>>" + username);


    getAllPosts()




  }

  //const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
        <tbody>
          {data.content.map((page, index) => {
            return (
              <tr key={page.id} className='text-center'>
                <td>{page.id}</td>
                {/* <td>{index + 1}</td> */}
                {/* <td>{page.userid + " | " + page.name}</td> */}
                <td>{page.username}</td>
                <td>{page.lastcalltime}</td>
                <td>{page.portactive === 1 ? "Active" : "Deactive"}</td>
                <td>{page.portstatus === 0 ? "FREE" : "BUSY"}</td>
                <td> <button className=" mx-2 btn btn-primary" onClick={() => updateChannel(page.id)}>
                  <i className="fa fa-edit" ></i>
                </button>
                </td>


              </tr>
            );
          })}
        </tbody>
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP + `/User/ChannelSettingAction?pageNumber=${offset - 1}&&size=10&&username=${suser}`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))


  }


  const searchsubmit = async (e) => {
   // alert(suser)

    e.preventDefault();
    getAllPosts()

  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    if (offset > 0) {
      setOffset(offset);
    }


  }
  useEffect(() => {
    getAllPosts()
  }, [offset])


  return (
    <>


      <div className='row ms-1 me-4'>





        <div className="col-md-12 col-12 mybgcolor me-2 mt-3">



          <div className='row ms-2 me-3'>
            {/* model for add chhanel */}

            <button ref={addchannelref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal2">
              Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Channel</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submitaddChannel}>
                      <div className="mb-5 mt-5">
                        <label htmlFor="Channel" className="form-label">No Of Channel:</label>
                        <input type="text" className="form-control" id="channel" name="channel" value={channel}
                          onChange={(e) => setchannel(e.target.value)} required={true} />
                      </div>


                      <div className="d-grid gap-2">
                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                      </div>

                    </form>
                  </div>

                </div>
              </div>
            </div>









            {/* modal for update */}

            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Launch demo modal
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Edit Channel</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submiteditform}>
                      <div className="mb-2">

                        <input type="text" className="form-control" id="echannelid" name="echannelid" value={echannelid}
                          onChange={(e) => setEchannelid(e.target.value)} required={true} readOnly={true} />
                      </div>

                      <div className="mb-2">
                        <label htmlFor="user" className="form-label">User Name</label>
                        <select id="usernameiddropdownl" className="form-select" name="euserid" onChange={(e) => seteuserid(e.target.value)} required>
                          <option value={modelusername}>{modelusername} | {modelusername}</option>
                          <option value=""></option>


                        </select>
                      </div>

                      <div className="mb-2">

                        <label className="form-label">Active</label>
                        <select id="eactive" className="form-select" name="eactive"

                          onChange={(e) => setEactive(e.target.value)} required>
                          <option value={eactive}>{eactive === 1 ? "Active" : "DeActive"}</option>
                          <option value="1">Active</option>
                          <option value="0">DeActive</option>

                        </select>


                      </div>

                      <div className="mb-2">
                        <label className="form-label">Status</label>
                        <select id="estatus" className="form-select" name="estatus"

                          onChange={(e) => setEstatus(e.target.value)} required>
                          <option value={estatus}>{estatus === 0 ? "FREE" : "BUSY"}</option>
                          <option value="0">FREE</option>
                          <option value="1">BUSY</option>

                        </select>
                      </div>




                      <div className="d-grid gap-2">
                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                      </div>

                    </form>
                  </div>

                </div>
              </div>
            </div>




            {/* Update Channel */}

            {/* modal for bulk update */}

            <button ref={refbulk} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#refbulkmodel">
              Launch demo modal
            </button>
            <div className="modal fade" id="refbulkmodel" tabIndex="-1" aria-labelledby="refbulkmodel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="refbulkmodel">Bulk Channel Update</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form className=" p-2" onSubmit={submitbulkupdateform}>
                      <div className="mb-2">
                        <label htmlFor="user" className="form-label">From:</label>
                        <input type="text" className="form-control" id="from" name="from" value={from}
                          onChange={(e) => setFrom(e.target.value)} required={true} />
                      </div>


                      <div className="mb-2">
                        <label htmlFor="user" className="form-label">To:</label>
                        <input type="text" className="form-control" id="to" name="to" value={to}
                          onChange={(e) => setTo(e.target.value)} required={true} />
                      </div>

                      <div className="mb-2">
                        <label htmlFor="bulkuserid" className="form-label">User Name</label>
                        <select id="usernameiddropdownbulk" className="form-select" name="bulkuserid" onChange={(e) => setbulkuserid(e.target.value)} required>
                          {/* <option value={euserid}>{euserid} | {eusername}</option>
                                        <option value=""></option> */}


                        </select>
                      </div>

                      <div className="mb-2">

                        <label className="form-label">Active</label>
                        <select id="eactive" className="form-select" name="eactive"

                          onChange={(e) => setEactive(e.target.value)} required>
                          <option value={eactive}>{eactive === 1 ? "Active" : "DeActive"}</option>
                          <option value="1">Active</option>
                          <option value="0">DeActive</option>

                        </select>


                      </div>

                      <div className="mb-2">
                        <label className="form-label">Status</label>
                        <select id="estatus" className="form-select" name="estatus"

                          onChange={(e) => setEstatus(e.target.value)} required>
                          <option value={estatus}>{estatus === 0 ? "FREE" : "BUSY"}</option>
                          <option value="0">FREE</option>
                          <option value="1">BUSY</option>

                        </select>
                      </div>




                      <div className="d-grid gap-2">
                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                      </div>

                    </form>
                  </div>

                </div>
              </div>
            </div>





            <motion.div
              initial={{ x: 800 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5 }}
            >

              <div className='col-md-2'>
              </div>


              <div className='col-md-12'>

                <div className=''>
                  <div className="">
                    <div className='row '>
                      <div className='text-center'>
                        <div className='card-header'>Channel Setting</div>
                      </div>
                      <div className='mt-1 mb-1 '>
                        <button className='btn btn-primary btn-mysm float-start' onClick={addBulkChannel}>Update Channel</button>
                        <button className='btn btn-primary btn-mysm float-start mx-2' onClick={addChannel}>Add Channel</button>
                      </div>
                    </div>

                    <div className='mybgcolor'>
                      <div className="">

                        <table className="table table-hover table-bordered checkoddata ignoreline">
                          <thead className="bg-darkblue text-white ">
                            <tr className='p-5'>
                              <th>ID</th>

                              <th>Username</th>
                              <th>LastCallTime</th>
                              <th>Active</th>
                              <th>PortStatus</th>
                              <th>update</th>


                            </tr>
                          </thead>

                          {posts}
                        </table>

                        {/* Goto Pageno:<input type="text" className="col-md-1 mx-2 mb-3 mt-3" id="gotopage" aria-describedby="gotopage"
                placeholder="gotopage" onChange={onInputChange} /> */}
                        {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}


                      </div>
                    </div>


                    {/* Using React Paginate */}
                    <div className='row'>
                      <div className='col-md-3'>
                        <form
                          onSubmit={searchsubmit}
                        >
                          <input type="text" className="form-control" name="serachuser"
                            id="serachuserid"
                            value={suser}
                            onChange={(e) => setSuser(e.target.value)}
                            placeholder="Search By Username" />
                        </form>
                      </div>
                      <div className='col-md-3'>
                        <form onSubmit={noop}>
                          <input type="number" className="form-control" id="gotopage" aria-describedby="gotopage"
                            placeholder="gotopage" onChange={onInputChange} />

                        </form>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col-md-3'></div>
                      <div className='col-md-6'>
                        <ReactPaginate
                          previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}
                          nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                          breakLabel={".."}
                          breakClassName={"break-me"}
                          breakLinkClassName={"break-mef"}
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          forcePage={offset - 1}

                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"} />

                      </div>
                    </div>

                  </div>





                </div>
              </div>
            </motion.div>


          </div>
        </div>




      </div>

    </>
  )
}

export default ChannelSetting
