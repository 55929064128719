import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import axios from 'axios'

//import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FaRupeeSign } from 'react-icons/fa';
import { RiLockPasswordFill } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { Logout } from './Api';

import { CgAddR } from "react-icons/cg";
import { FaRegWindowClose } from "react-icons/fa";



//FaRupeeSign

// import AddCampaignform from './AddCampaignform';


const Users = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    const [addcampb, setAddcampb] = useState(false)


   
    



    const openCamp = () => {

        setAddcampb(true)

    }

    const closeCamp = () => {

        setAddcampb(false)

    }

    // const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const addcreditref = useRef(null)
    const changepwdref = useRef(null)



    const [euserprofileid, setEprofileid] = useState()
    const [ename, setEname] = useState("")
    const [email, setEmail] = useState("")

    const [eaddress, setEeaddress] = useState("NA")
    const [emobile, setEemobile] = useState("NA")
    const [esip, setEesip] = useState("NA")
    const [edid, setEedid] = useState("NA")
    const [ecampaign, setEecampaign] = useState("NA")
    const [ebackp, setEebackp] = useState("NA")
    
    const [eobdpulse, setEeobdpulse] = useState("NA")
    const [evoicecredit, setEevoicecredit] = useState("NA")
    const [evoicecreditconsume, setEevoicecreditconsume] = useState("NA")

    const updateCampaign = async (e) => {

        ref.current.click();

        var formdata = new FormData()
        formdata.append('id', e)
        //alert(e);
        //const response = await fetch(process.env.REACT_APP_IP+"/Admin/getUserById", {
        const response = await fetch(process.env.REACT_APP_IP + "/User/getUserByUseid", {

            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

        setEprofileid('')
        setEname('')
        setEeaddress('')

        setEemobile('')
        setEesip('')
        setEedid('')
        setEecampaign('')
        setEebackp('')
       
        setEeobdpulse('')
        setEevoicecredit('')
        setEevoicecreditconsume('')
        setEmail('')
        setEprofileid(json.id)

        setEname(json.userpro.name)
        setEmail(json.userpro.email)
        setEeaddress(json.userpro.address)

        setEemobile(json.userpro.mobile)
        setEesip(json.sip)
        setEedid(json.userpro.did)
        setEecampaign(json.campaign)
        setEebackp(json.userpro.backp)
       
        setEeobdpulse(json.userpro.obdpulse)
        setEevoicecredit(json.userpro.voicecredit)
        setEevoicecreditconsume(json.voicecreditconsume)

    }


    const [addcreditid, setAddcreditid] = useState("")
    const [addcreditusername, setAddcreditusername] = useState("")
    const [credita, setcredita] = useState("")

    const [suser, setSuser] = useState("")


    const addCredit = async (e1, e2) => {
        setAddcreditid(e1)
        setAddcreditusername(e2)
        //alert(e2)
        addcreditref.current.click();

    }

    const deleteCampaign = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP + "/User/DeleteUser", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json.msg)

        if (json.msg === "Success") {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Deleted Successfully",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: 'Deleted Successfully',
                showConfirmButton: false,
                timer: 1500
            })

        } else {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: 'Error ',
                showConfirmButton: false,
                timer: 1500
            })
        }

        getAllPosts()





    }

    const searchsubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.get(process.env.REACT_APP_IP + `/User/CheckUserLike?username=${suser}&&pageNumber=${offset - 1}&&size=6`,
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('token')}`
                    }
                })
            const searchdata = res.data;
            const postData = SearchUsernamePostData(searchdata)
            // Using Hooks to set value
            setAllPosts(postData)
            // setPageCount(Math.ceil(data.length / postsPerPage))
            setPageCount(Math.ceil(searchdata.totalPages))
        } catch (error) {
            alert("Token Expire Plz Logout And Login Again")
            Logout()
            console.error('Error In DeleteRinggroup ', error);
        }

    }



    const [changeuserid, setchangeuserid] = useState("")
    const [changeusername, setchangeusername] = useState("")
    const [changepwda, setchangepwda] = useState("")


    const changePwd = async (e1, e2) => {
        setchangeuserid(e1)
        setchangeusername(e2)
        //alert(e2)
        changepwdref.current.click();

    }
    const changepwdsubmit = async (e) => {
        e.preventDefault();
        // setAddcreditid(e1)
        // setAddcreditusername(e2)
        //alert(addcreditid+">>>>>>"+credita)
        var formdata = new FormData()
        formdata.append('id', changeuserid)
        formdata.append('pwd', changepwda)
        const response = await fetch(process.env.REACT_APP_IP + "/User/ChangePwd", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json', 
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        // console.log(response)
        const json = await response.json()
        // console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()
        changepwdref.current.click();

    }

    const addCreditsubmit = async (e) => {
        e.preventDefault();
        // setAddcreditid(e1)
        // setAddcreditusername(e2)
        //alert(addcreditid+">>>>>>"+credita)
        var formdata = new FormData()
        formdata.append('id', addcreditid)
        formdata.append('credit', credita)
        const response = await fetch(process.env.REACT_APP_IP + "/User/AddCredit", {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json', 
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        // console.log(response)
        const json = await response.json()
        // console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()
        addcreditref.current.click();

    }






    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }

    const noop = async (e) => {

        e.preventDefault();
    }

    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(euserprofileid + ">>>>>>>" + ename + ">>>>>>>>>" + ecampaign + ">>>>>>>>>" + eaddress
            + ">>>>>>>>" + emobile + ">>>>>>>>>>>" + esip + ">>>>>>>>>>>" + edid + ">>>>>>>>>>>" + ebackp
            + ">>>>>>>>>>>" +  eobdpulse + ">>>>>>>>>>>" + evoicecredit + ">>>>>email>>>>>" + email)

        var formdata = new FormData()
        formdata.append('id', euserprofileid)
        formdata.append('name', ename)
        formdata.append('email', email)
        formdata.append('campaign', ecampaign)
        formdata.append('address', eaddress)
        formdata.append('mobile', emobile)
        formdata.append('sip', esip)
        formdata.append('did', edid)
        formdata.append('backp', ebackp)
       // formdata.append('validity', evalidity)
        formdata.append('obdpulse', eobdpulse)
        formdata.append('voicecredit', evoicecredit)
        // formdata.append('voicecreditconsume', evoicecreditconsume)





        const response = await fetch(process.env.REACT_APP_IP + "/User/EditUserProfile", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        console.log(response)

        ref.current.click();
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        getAllPosts()

    }





    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllUserbyuserid?pageNumber=${offset - 1}&&size=10`,
                {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('token')}`
                    }
                })
            const data = res.data;
            const postData = getPostData(data)
            // Using Hooks to set value
            setAllPosts(postData)
            // setPageCount(Math.ceil(data.length / postsPerPage))
            setPageCount(Math.ceil(data.totalPages))
        } catch (error) {
            alert("Token Expire Plz Logout And Login Again")
            Logout()
            console.error('Error In DeleteRinggroup ', error);
        }

    }

    const getSearchDataPosts = async (username) => {


    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [mob, setMob] = useState("");
    const [emailuser, setEmailuser] = useState("");

    const [roles, setRoles] = useState("");






    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addcampaignSubmit = async (event) => {
        event.preventDefault();
        //alert(`The name you entered was: ${campname}`)

        const response = await fetch(process.env.REACT_APP_IP + "/User/AddUser", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "username": username,
                "password": password,
                "mobile": mob,
                "email": emailuser,
                "roles": roles

            })
            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()
        // setEprofileid('')
        // setEname('')
        // setEeaddress('')

        // setEemobile('')
        // setEesip('')
        // setEedid('')
        // setEecampaign('')
        // setEebackp('')
        
        // setEeobdpulse('')
        // setEevoicecredit('')
        // setEevoicecreditconsume('')

  setAddcampb(false)

    }


    // SearchDataResult By Username

    const SearchUsernamePostData = (searchdata) => {
        return (
            <>

                {searchdata.content.map(page => {
                    return (
                        <tr key={page.id}>
                            {/* <td>{page.id}</td> */}
                            <td>
                                <button className=" btn btn-primary btn-mysm" onClick={() => updateCampaign(page.id)}>
                                    <i className="far fa-edit" ></i>
                                </button>

                            </td>
                            <td>                                     {page.username}

                            </td>
                            <td>
                                <button className="btn btn-primary btn-sm" onClick={() => changePwd(page.id, page.username)}>
                                    <RiLockPasswordFill className='mx-1' />
                                </button>

                            </td>
                            <td>
                                <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-6'>
                                        {page.userpro.voicecredit}
                                    </div>
                                    <div className='col-md-6'>
                                        <button className=" btn btn-primary btn-sm" onClick={() => addCredit(page.id, page.username)}>
                                            <FaRupeeSign />      </button>
                                    </div>
                                </div>



                            </td>
                            <td>
                                {page.userpro.voicecredit - page.userpro.voicecreditconsume}

                            </td>



                            <td>
                                {page.userpro.name}
                            </td>

                            <td>{page.mobile}</td>
                            <td>
                                {page.userpro.did}
                            </td>
                            <td>
                                {page.userpro.obdpulse}
                            </td>

                            <td>{page.email}</td>
                            <td>{page.roles.substring(5)}</td>


                            <td>
                                {page.userpro.backp}

                            </td>


                             <td> <i className="far fa-trash-alt mx-2 btn btn-danger" onClick={() => deleteCampaign(page.id)}></i></td> 




                        </tr>
                    );
                })}



            </>

        )

    }



    const getPostData = (data) => {
        return (
            <>




                {data.content.map(page => {
                    return (
                        <tr key={page.id}>

                            <td className='text-center'>
                                <button className=" btn btn-primary btn-mysm" onClick={() => updateCampaign(page.id)}>
                                    <i className="far fa-edit" ></i>
                                </button>

                            </td>
                            {/* <td>{page.id}</td> */}
                            <td>                                     {page.username}

                            </td>

                            <td className='text-center'>
                                <button className="btn btn-primary btn-mysm" onClick={() => changePwd(page.id, page.username)}>
                                    <RiLockPasswordFill className='mx-1' />
                                </button>

                            </td>
                            <td>
                                <div className='row d-flex align-items-center justify-content-center'>
                                    <div className='col-md-6'>
                                        <div className=''>
                                                {page.userpro.voicecredit}
                                        </div>
                                        
                                    </div>
                                    <div className='col-md-6'>
                                        <button className=" btn btn-primary btn-mysm" onClick={() => addCredit(page.id, page.username)}>
                                            <FaRupeeSign />      </button>
                                    </div>
                                </div>



                            </td>
                            <td>
                                {page.userpro.voicecredit - page.userpro.voicecreditconsume}

                            </td>


                            <td>
                                {page.userpro.name}
                            </td>

                            <td>{page.mobile}</td>
                            <td>
                                {page.userpro.did}
                            </td>
                            <td>
                                {page.userpro.obdpulse}
                            </td>

                            <td>{page.email}</td>
                            <td>{page.roles.substring(5)}</td>


                            <td>
                                {page.userpro.backp}

                            </td>


                             <td> <i className="far fa-trash-alt mx-2 btn btn-danger" onClick={() => deleteCampaign(page.id)}></i></td> 




                        </tr>
                    );
                })}



            </>

        )

    }


    return (

        <>




            <div className='row  me-2 mt-1'>


                <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Edit/View demo modal
                </button>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit/View User Profile</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form className=" p-2" onSubmit={submiteditform}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Id</label>
                                                <input type="text" className="form-control" id="euserprofileid" name="euserprofileid" value={euserprofileid}
                                                    onChange={(e) => setEprofileid(e.target.value)} required={true} readOnly={true} />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="ename" name="ename" value={ename}
                                                    onChange={(e) => setEname(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Credit</label>
                                                <input type="text" className="form-control" id="evoicecredit" name="evoicecredit" value={evoicecredit}
                                                    onChange={(e) => setEevoicecredit(e.target.value)} required />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Address</label>
                                                <input type="text" className="form-control" id="eaddress" name="eaddress" value={eaddress}
                                                    onChange={(e) => setEeaddress(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Mobile</label>
                                                <input type="text" className="form-control" id="emobile" name="emobile" value={emobile}
                                                    onChange={(e) => setEemobile(e.target.value)} required />
                                            </div>
                                            {/* <div className="mb-2">
                                <label htmlFor="title" className="form-label">Sip</label>
                                    <input type="text" className="form-control" id="esip" name="esip" value={esip}
                                        onChange={(e) => setEesip(e.target.value)} required={true}  />
                                    </div> */}


                                        </div>
                                        <div className='col-md-6'>

                                            {/* <div className="mb-2">
                                            <label htmlFor="title" className="form-label">Campaign</label>
                                            <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                                onChange={(e) => setEecampaign(e.target.value)} required />
                                    </div> */}



                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Did</label>
                                                <input type="text" className="form-control" id="edid" name="ename" value={edid}
                                                    onChange={(e) => setEedid(e.target.value)} required />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Backup</label>
                                                <input type="text" className="form-control" id="ecampaign" name="ebackp" value={ebackp}
                                                    onChange={(e) => setEebackp(e.target.value)} required  readOnly={true} />
                                            </div>



                                            
                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">OBD Pulse</label>
                                                <input type="text" className="form-control" id="eobdpulse" name="eobdpulse" value={eobdpulse}
                                                    onChange={(e) => setEeobdpulse(e.target.value)} required />
                                            </div>

                                            <div className="mb-2">
                                                <label htmlFor="title" className="form-label">Email</label>
                                                <input type="text" className="form-control" id="email" name="ename" value={email}
                                                    onChange={(e) => setEmail(e.target.value)} required />
                                            </div>

                                            {/* <div className="mb-2">
                                        <label htmlFor="title" className="form-label">Credit Consume</label>
                                        <input type="text" className="form-control" id="evoicecreditconsume" name="evoicecreditconsume" value={evoicecreditconsume}
                                            onChange={(e) => setEevoicecreditconsume(e.target.value)} required />
                                </div> */}


                                        </div>

                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>
                                    </div>
                                </form>

                            </div>
                            <div className="modal-footer">
                                {/* <button ref={refClose} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}

                                {/*                             
                            <button disabled={campaign.etitle.length || campaign.edescription.length} onClick={handleClick} type="button" className="btn btn-primary">Update campaign</button>
                         */}

                            </div>
                        </div>
                    </div>
                </div>



                {/*  Add Credit  */}

                <button ref={addcreditref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleAddCredit">
                    Add Credit modal
                </button>
                <div className="modal fade" id="exampleAddCredit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Credit</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form className=" p-2" onSubmit={addCreditsubmit}>


                                    <div className="mb-2">
                                        <h3> Adding Credit To <b style={{ textDecoration: "underline" }}>{addcreditid} | {addcreditusername}</b></h3>
                                        <label htmlFor="title" className="form-label">Id</label>
                                        <input type="text" className="form-control" value={addcreditid} readOnly />
                                    </div>


                                    <div className="mb-2">

                                        <label htmlFor="title" className="form-label">Credit</label>
                                        <input type="number" className="form-control"
                                            value={credita}
                                            onChange={(e) => setcredita(e.target.value)} required />
                                    </div>


                                    <div className="d-grid gap-2">
                                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                    </div>


                                </form>

                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>



                {/* pwd change modal */}

                <button ref={changepwdref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#examplepwdchange">
                    pwd change modal
                </button>
                <div className="modal fade" id="examplepwdchange" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Change Password</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form className=" p-2" onSubmit={changepwdsubmit}>


                                    <div className="mb-2">
                                        <h3> Changing Password  <b style={{ textDecoration: "underline" }}>{changeuserid} | {changeusername}</b></h3>
                                        <label htmlFor="title" className="form-label">Id</label>
                                        <input type="text" className="form-control" value={changeuserid} readOnly />
                                    </div>


                                    <div className="mb-2">

                                        <label htmlFor="title" className="form-label">Password</label>
                                        <input type="password" className="form-control"
                                            value={changepwda}
                                            onChange={(e) => setchangepwda(e.target.value)} required />
                                    </div>


                                    <div className="d-grid gap-2">
                                        <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                    </div>


                                </form>

                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>





                 <div className={addcampb? "row mt-5":"d-none"}>
                    <div className='col-md-4'>

                    </div>
                <div className='col-md-4  col-12 text-nowrap '>

                    <form className='card ms-1'
                        //  style={{
                        //     backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)"
                        //     , minHeight: "700px"

                        // }} 

                        onSubmit={addcampaignSubmit} >
                        <div className=''>
                            
                            <div className=''>
                                {/* <h4 className=' text-center card-header'>Add User</h4> */}
                                <div>
                                    <button className='btn btn-danger btn-sm float-end' onClick={() => closeCamp()}>
                                        <FaRegWindowClose className='' size="25" /></button>
                                </div>
                                <div className='card-header text-center'>Add User

                                </div>
                            </div>
                            <div className='p-2'>
                            <div className=" ">
                                <label>User Name:</label>
                                <input type="text" className="form-control" id="username" placeholder="Enter username"
                                    name="username" value={username}
                                    onChange={(e) => setUsername(e.target.value)} required />

                            </div>


                            <div className="mb-2 mt-2">
                                <label>Password:</label>
                                <input type="text" className="form-control" id="password" placeholder="Enter Password"
                                    name="password" value={password}
                                    onChange={(e) => setPassword(e.target.value)} required />

                            </div>

                            {/* <div className="form-floating mb-2 ">
                            <input type="number" className="form-control" id="mobile" placeholder="Enter Mobile"
                                name="mobile" value={mob}
                                onChange={(e) => setMob(e.target.value)} required />
                            <label htmlFor="mobile">Mobile</label>
                        </div>

                        <div className="form-floating mb-2 ">
                            <input type="text" className="form-control" id="password" placeholder="Enter Email"
                                name="password" value={emailuser}
                                onChange={(e) => setEmailuser(e.target.value)} required />
                            <label htmlFor="password">Email</label>
                        </div> */}

                            {/* <div className="form-floating mb-3 mt-1">
                            <input type="text" className="form-control" id="campaign_mode" placeholder="Enter campaign_mode"
                             name="campaign_mode"  value={campaign_mode} 
                             onChange={(e) => setcampaign_mode(e.target.value)} />
                            <label htmlFor="campaign_mode">Campaign Mode</label>
                        </div> */}
                            <div className=" mb-2">
                                <label>User Type:</label>
                                <select className="form-select" name="roles" value={roles}
                                    onChange={(e) => setRoles(e.target.value)} required>
                                    <option value="">Select User Type</option>
                                    <option value="ROLE_USER">USER</option>
                                    {/* <option value="ROLE_APIOBDUSER">OBDAPI</option> */}
                                </select>

                            </div>






                            <input className="btn btn-primary mt-3 form-control text-white" type="submit" value="Submit" />
                            </div>
                        </div>
                    </form>

                </div>
                <div className='col-md-4'>

                    </div>
                </div>

                <div className={addcampb ? "d-none" : ""}>
                <div className="col-md-12 col-12">

                    <div className='card text-nowrap '>
                        <div className=' text-black'>
                            <h2 className='text-center card-header'>
                                
                            <button className='btn btn-success btn-md float-start' onClick={() => openCamp()}>
                            <CgAddR className='me-1 text-white' style={{ color: "white" }} />AddUser</button>
                                Existing User</h2>

                              


                            <div className="table-responsive p-1">
                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                    <thead className="bg-darkblue text-white">
                                        <tr>
                                            {/* <th>ID</th> */}
                                            <th>Edit</th>
                                            <th>User Name</th>

                                            <th>PwdChange</th>
                                            <th>Total Credit</th>

                                            <th>Credit Remain</th>

                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Did</th>
                                            <th>Pulse Rate</th>
                                            <th>Email</th>

                                            <th>User Type</th>
                                            <th>Pwd</th>

                                             <th>Delete</th> 


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {posts}
                                    </tbody>
                                </table>

                                <div className='row'>
                                    <div className='col-md-3'>
                                        <form onSubmit={searchsubmit}>
                                            <input type="text" className="form-control" name="serachuser"
                                                id="serachuserid" value={suser}
                                                onChange={(e) => setSuser(e.target.value)}
                                                placeholder="Search User" />
                                        </form>
                                    </div>
                                    <div className='col-md-3'>
                                        <form onSubmit={noop}>
                                            <input type="number" className="form-control" id="gotopage" aria-describedby="gotopage"
                                                placeholder="gotopage" onChange={onInputChange} />

                                        </form>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>

                                    </div>
                                    <div className='col-md-6'>
                                        <ReactPaginate
                                           previousLabel={<i className="fa-solid fa-angles-left"></i>}
                                           nextLabel={<i className="fa-solid fa-angles-right"></i>}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            breakLinkClassName={"break-mef"}
                                            pageCount={pageCount}
                                            onPageChange={handlePageClick}
                                            forcePage={offset - 1}

                                            containerClassName={"pagination"}
                                            subContainerClassName={"pages pagination"}
                                            activeClassName={"active"}

                                        />
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>





            {/* <form onSubmit={searchsubmit}>
                        <input type="text" className="form-control"  name="serachuser"
                        id="serachuserid" value={suser}
                        onChange={(e) => setSuser(e.target.value)}
                        placeholder="Search User" />
                    </form> */}

            {/* Display all the posts */}


            <div className="">
                <div className="col-md-6 col-12"></div>

                <div className="col-md-6 col-12">





                </div>

            </div>








        </>

    )


}


export default Users
