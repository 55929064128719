import React, { useState } from 'react'
import Swal from 'sweetalert2'

const Changepwd = () => {

  const [pwd , setPwd] = useState()
  const [confpwd , setConfpwd] = useState()



const changepwdfun = async(e) => {
 
e.preventDefault();
var formdata = new FormData()
formdata.append('pwd', pwd)

if(pwd===confpwd)
{
    //alert("matched")
    const response = await fetch(process.env.REACT_APP_IP + "/User/Passwordchange", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }
        setPwd('')
        setConfpwd('')


}else{
    alert("pwd not matched")
    return
}

}



  return (
    <>
    
    <div className="row me-3">
        <div className="col-md-2">

        </div>
        <div className="col-md-8">
        <form className=' mt-2 mb-4 p-5' onSubmit={changepwdfun}>
    <div className='card mb-5'>
        <div className='mycard-header'>
            <h1 className='text-center text-white'>Change Password</h1>
        </div>
        <div className='mycard-body form-control'>
             <div className='row '>
                 <div className="col-md-2">
                </div>
                
                <div className="col-md-8 p-5">
                    <label >Enter New Password:</label>
                    <input className='form-control mt-2' type="password" 
                    onChange={(e) => setPwd(e.target.value)} name="pwd" value={pwd} id="pwd" required />
                    <label className='mt-4'>Confirm Password:</label>
                    <input className='form-control mt-2' type="password" name="confpwd" value={confpwd} onChange={(e) => setConfpwd(e.target.value)} required />
                    <div class="text-center">
                         <input className='btn btn-primary form-control text-white mt-3 ' type="submit" value="submit" />
                    </div>
                    
                </div>
                <div className="col-md-2">
                </div>
             </div>
                  
                    
            
        </div>
    </div>
  

    </form>
            
        </div>

        <div className="col-md-2">
            
        </div>
    </div>
   
    
    
    
    
    
    </>
  )
}

export default Changepwd