import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { motion } from 'framer-motion';
import { useLocation } from "react-router" 

const Createmenu = () => {
    const location = useLocation();
    const state = location.state;

 // const [postsPerPage] = useState(5);
 const [ivrid, setIvrid] = useState(state.id);


 const [sms, setSms] = useState("Yes");

 const [desc, setDesc] = useState("");

 
 
 const [offset, setOffset] = useState(1);
 const [posts, setAllPosts] = useState([]);
 const [pageCount, setPageCount] = useState(0)

 const getAllPosts = async () => {
    try{
     const res = await axios.get(process.env.REACT_APP_IP+`/User/AllIvrmenupage?pageNumber=${offset - 1}&&size=10&&ivrname=${state.ivrname}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
     const data = res.data;
     const postData = getPostData(data)
     // Using Hooks to set value
     setAllPosts(postData)
     // setPageCount(Math.ceil(data.length / postsPerPage))
     setPageCount(Math.ceil(data.totalPages))
    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }
 }
 
 useEffect(() => {
    getAllPosts()
}, [offset])


// deleteCampaign
const DeleteRinggroup = async (e) => {
try{
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/DeleteMenuivr", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
   const json = await response.json()
   console.log(json.msg)
   
   if (json.type === "OK") {
    // props.showAlert(json.msg, "success");
    Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Success',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 2500
    })
} else {
    //props.showAlert(json.msg, "danger");
    Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 4500
    })
}
    
    getAllPosts()
    
} catch (error) {
    console.error('Error In getAllPosts ', error);
  }



}

const onToggle = async (e) => {
    try{
    var formdata = new FormData()
    formdata.append('id', e)
    const response = await fetch(process.env.REACT_APP_IP+"/User/ActiveGroup", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
           // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: formdata

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }
    getAllPosts()
   // setIsToggled(!isToggled);
} catch (error) {
    console.error('Error In getAllPosts ', error);
  }

}
const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
};

const getPostData = (data) => {
    return (
        <>
          

                                        {data.content.map((page,index) => {
                                            return (
                                                <tr key={page.id}>
                                                      <td>{(offset - 1)*5+index+1}</td> 
                                                    <td>{page.userid}</td>
                                                    <td>{page.ivrname}</td>
                                                    <td>{page.desc}</td>
                                                    <td>{page.uploaddate}</td>
                                                    <td>{page.destinationtype}</td>
                                                   
                                                    <td>{page.dtmf}</td>
                                                    <td>{page.sms}</td>
                                                   
                                                    
                                                    <td>
                                                        <button className=" mx-2 btn btn-danger" 
                                                        onClick={() => DeleteRinggroup(page.id)}
                                                        >
                                                            <i className="fa fa-trash" ></i>
                                                        </button>

                                                         {/* <i className="fa fa-trash mx-2" onClick={() => deleteCampaign(page.id)}></i> */}
                                                         
                                                         
                                                         </td>




                                                </tr>
                                            );
                                        })}
                                    
        </>

    )

}


 const onInputChange = (event) => {

     const offset = Math.ceil(event.target.value);
     setOffset(offset);

 }
   
    
    const [ringbackaudio, setRingbackaudio] = useState("");
    const [timeoutaudio, setTimeoutaudio] = useState("");
    const[active,setActive] =  useState("");
    const[groupname,setGroupname] =  useState("");
    const[groupdes,setGroupdes] =  useState("");
    const[strategy,setStrategy] =  useState("");
    const [uploaduserid, setUploaduserid] = useState("NA");
    


     const Routesubmitfun = async(event) =>{
        try{
        event.preventDefault();
        // alert(
        //     ">>>>>>Destination>>>"+strategy+
        //     ">>>>>>option>>>"+groupdes+
        //     ">>>>>>desc>>>"+desc+
            
        //     ">>>>>>groupname>>>"+state.ivrname+
        //     ">>>>>>sms>>>"+sms+
        //     ">>>>>>groupname id>>>"+state.id
            
        // )

        var formdata = new FormData()
        formdata.append('userid', state.username)
        formdata.append('ivrname', state.ivrname)
        formdata.append('dtmf', groupdes)
        formdata.append('destination', strategy)
        formdata.append('desc', desc)
        formdata.append('sms', sms)
      
        
       
        

    const response = await fetch(process.env.REACT_APP_IP+"/User/AddIvrmenu", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },

        headers: {
            // 'Content-Type': 'application/json',

            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body:formdata
        // body: JSON.stringify({
           
        // })

        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
        // props.showAlert(json.msg, "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 2500
        })
    } else {
        //props.showAlert(json.msg, "danger");
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'error',
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            text: json.msg,
            showConfirmButton: false,
            timer: 4500
        })
    }

    getAllPosts()

} catch (error) {
    console.error('Error In getAllPosts ', error);
  }

     }

    const getAllCallpatchAgent = async (e) => {
        try{
        var formdata = new FormData()
        formdata.append('username', state.username)
        //alert(">>>>"+e)
        const resppe = await fetch(process.env.REACT_APP_IP+"/User/RestAllcallpatchbyusername", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })

        const ringgroupres = await fetch(process.env.REACT_APP_IP+"/User/RestAllRinggrouplist", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const jsonb = await ringgroupres.json()
        //console.log(jsona.length)

       

        const jsona = await resppe.json()
        //console.log(jsona.length)

        var ele = document.getElementById('agentid');
       
       // ele.innerHTML = '<optgroup label="Agents">'
        ele.innerHTML = ""
        //+('<option  value="NA">------Select-----</option>');
        
        for (var l = 0; l < jsona.length; l++) {
            // POPULATE SELECT ELEMENT WITH JSON.
            ele.innerHTML = ele.innerHTML +
                '<option className="myopt"   value="' + jsona[l]['id']+'|'+"Agents"+ '">' + jsona[l]['agentnumber'] +' | '+ jsona[l]['agentname']+'</option>';
        }

        // ele.innerHTML = '<option  value="NA"></option>'+'<optgroup label="Agents">'+ele.innerHTML+('</optgroup>');
       
       
         //var bc=ele.innerHTMLele.innerHTML;

        // var ele = document.getElementById('groupid');
        // ele.innerHTML = ""+('<option value="NA">NA</option>');
       // var ele2 = "";
      //  ele.innerHTML = ""
      var cd="";
         for (var l = 0; l < jsonb.length; l++) {
             // POPULATE SELECT ELEMENT WITH JSON.
             cd = cd +
                 '<option  value="' + jsonb[l]['id'] +'|'+"RingGroup"+ '">' + jsonb[l]['name'] + '</option>';
         }

         ele.innerHTML = '<option  value="NA"></option>'+'<optgroup label="Agents">'+ele.innerHTML+('</optgroup>')
         +'<optgroup label="RingGroup">'+cd+('</optgroup>');
       
         //ele.innerHTML = ele.innerHTML+'<optgroup label="RingGroup">'+cd+('</optgroup>');

         //ele.innerHTML = ele.innerHTML+'<optgroup label="RingGroup">'+ele2.innerHTML+('</optgroup>');


    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }

    }

    useEffect(() => {
        getAllCallpatchAgent()
      }, [])


      const changeuserfun = async(e) =>{

        setUploaduserid(e)
      //  optionaudio(e)


      }

    

  
    const optionforusername = async (e) => {
        try{
        const respp = await fetch(process.env.REACT_APP_IP+"/User/RestAllAdmindrop", {
          method: 'POST',
          // headers: {
          //     'Content-Type': 'application/json'
          // },
    
          headers: {
            'Content-Type': 'application/json',
    
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
    
          // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        })
        const json = await respp.json()
        console.log(json.length)
       
        var elelist = document.getElementById('userselectid');
        elelist.innerHTML = ""+('<option value="">NA</option>');
    
        for (var i = 0; i < json.length; i++) {
          // POPULATE SELECT ELEMENT WITH JSON.
          elelist.innerHTML = elelist.innerHTML +
            '<option  value="' + json[i]['username'] + '">' + json[i]['username'] +  '</option>';
        }
    
    } catch (error) {
        console.error('Error In getAllPosts ', error);
      }
    
    
    
      }
    
      useEffect(() => {
        optionforusername()
      }, [])

    


  return (
    <>
    
    <div className='row'>
        <div className='col-md-3'>
               <div className='row'>
                       <form onSubmit={Routesubmitfun}>
                                    <div className='col-md-12'>
                                    <div className='card-header text-center bg-dark text-white p-2 ms-1'>Menu For-{state.ivrname}</div>
                                        <div className='card p-2 ms-1'>
                                       
                                        <div className='mt-2'>
                                            <label>IvrName</label>
                                            <input type="text" className='form-control'
                                            value={state.ivrname}
                                            // onChange={(e) => setGroupname(e.target.value)}
                                            readOnly={true}
                                            required/>
                                        </div>
                                        <div className='mt-2'>
                                            <label>Option</label>
                                            <input type="number" className='form-control' 
                                             value={groupdes}
                                             onChange={(e) => setGroupdes(e.target.value)}
                                            
                                            required/>
                                        </div>
                                        <div className='mt-2'>
                                            <label>Destination</label>
                                            <select id="agentid" className="form-select"
                                                value={strategy}
                                                onChange={(e) => setStrategy(e.target.value)} 
                                                required> 
                                                
                                                                                          
                                            </select>
                                        </div>
                                        
                                        <div className="mt-2">   
                                            <label>SMS</label>                                      
                                            <select id="ringbackaudioid" className="form-select" name="audio"
                                            
                                            value={sms}
                                            onChange={(e) => setSms(e.target.value)} 
                                            
                                            required>    
                                                <option value="Yes">Yes</option>     
                                                <option value="No">No</option>                                        
                                            </select>
                                            
                                        </div>
                                        <div className='mt-2'>
                                            <label>Desc</label>
                                            <input type="text" className='form-control' 
                                             value={desc}
                                               onChange={(e) => setDesc(e.target.value)}
                                            
                                            required/>
                                        </div>
                                       
                                       
                                       
                                       
                                        <div className='mt-3'>
                                            
                                            <input type="submit" className="btn btn-primary btn-sm form-control text-white" />
                                        </div>
                                        </div>
                                    
                                    </div>
                                    <div className='col-md-6'>
                                
                                </div>

                       </form>

                        

               </div>
        </div>
        <div className='col-md-9'>
        <div className=' text-black'>
                                <h4 className='text-center card-header mt-2'>Existing Menu For-{state.ivrname}</h4>

                                <div className="table-responsive mt-3 text-nowrap">

                                    <table className="table table-hover table-bordered">
                                        <thead className="bg-darkblue text-white">
                                            <tr>
                                                <th>S.N</th>
                                                <th>UserName</th>
                                                <th>IvrName</th>
                                                <th>Desc</th>
                                                <th>Date</th>
                                                <th>DestinationType</th>
                                                <th>Option</th>
                                                <th>Sms</th>                                                                                           
                                                <th>Delete</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                           {posts}
                                        </tbody>
                                        
                                       
                                    </table>
                                    Goto Pageno:<input type="text" className="col-md-1 mx-2" id="gotopage" aria-describedby="gotopage"
                                        placeholder="gotopage"
                                        //  onChange={onInputChange} 
                                         />

                                </div>
                            </div>




                            <div className="row mb-5">
                    <div className="col-md-3 col-12"></div>

                    <div className="col-md-6 col-12">


                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            breakLinkClassName={"break-mef"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            forcePage={offset - 1}

                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}

                        />


                    </div>

                </div>



        </div>
    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    </>
  )
}

export default Createmenu